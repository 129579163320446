import React from "react"
import styled from "styled-components"

import JOB_FAMILY_GROUPS from "@content/job-family-groups"

import theme from "@assets/styles/theme"
import UnstyledLink from "@components/elements/UnstyledLink"
import {
  getJobFamilyUrl,
  getJobFamilyGroupSlugFromJobFamilySlug,
} from "@helpers/url"
import { getAllJobFamilies } from "@helpers/job"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1px 1fr;
  padding: 20px 24px;
  row-gap: 16px;

  &:nth-child(odd) {
    background-color: rgba(240, 240, 240, 0.5);
  }

  @media (max-width: 1023px) {
    background-color: rgba(240, 240, 240, 0.5);
    grid-template-columns: auto;
    grid-template-rows: auto auto 1px auto;
    padding-top: 0;
    margin-bottom: 16px;
    row-gap: 0;

    &.type-T {
      border-top: 3px solid ${theme.colors.secondary.orange};
    }

    &.type-B {
      border-top: 3px solid ${theme.colors.secondary.yellow};
    }

    &.type-P {
      border-top: 3px solid ${theme.colors.primary.blue};
    }

    &.type-L {
      border-top: 3px solid ${theme.colors.secondary.green};
    }

    &.type-unavailable {
      border-top: 3px solid rgba(240, 240, 240, 0.5);
    }
  }
`

const Arrow = styled.div`
  padding-top: calc(var(--box-top-height) / 2);

  @media (max-width: 1023px) {
    padding: 0;
    display: flex;
    justify-content: center;
  }
`

const BoxGroup = styled.div`
  display: flex;
  align-items: top;

  > ${Arrow} {
    width: calc(var(--arrow-horizontal-length) / 2 - 1px);
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;

    > ${Arrow} {
      height: 18px;
      width: 1px;
    }
  }
`

const Line = styled.div`
  background-color: var(--arrow-color);
`

const LineHorizontal = styled(Line)`
  width: 100%;
  height: 1px;

  @media (max-width: 1023px) {
    width: 1px;
    height: 100%;
  }
`

const LineVertical = styled(Line)`
  width: 1px;
  /* height: ${props => "calc(20px + 60px + 16px + 20px + 1px)" || "1px"}; */
  height: ${props => props.lineHeight || "1px"};
  /* Just compute this with how many children there are in the stack
      20 + 60 + 16 is the fixed height of the first box, with padding
      extra 20 is added to get to the second box's starting point
      It originally has 1px height so add that only once)
      No need to set height if only one child */

  @media (max-width: 1023px) {
    height: 1px;
  }
`

const ArrowRight = styled.div`
  width: 0;
  height: 0;
  border-top: var(--arrow-head-size) solid transparent;
  border-bottom: var(--arrow-head-size) solid transparent;
  border-left: var(--arrow-head-size) solid var(--arrow-color);

  @media (max-width: 1023px) {
    border-left: var(--arrow-head-size) solid transparent;
    border-right: var(--arrow-head-size) solid transparent;
    border-top: var(--arrow-head-size) solid var(--arrow-color);
  }
`

const ArrowWithHead = styled(Arrow)`
  position: relative;

  > ${LineHorizontal} {
    width: calc(100% - var(--arrow-head-size));

    @media (max-width: 1023px) {
      height: 12px;
      width: 1px;
    }
  }

  > ${ArrowRight} {
    position: absolute;
    right: 0px;
    top: 14.5px;

    @media (max-width: 1023px) {
      right: -5.5px;
      bottom: -6px;
      top: auto;
    }
  }

  &.not-first-item {
    @media (max-width: 1023px) {
      display: none;
    }
  }
`

const Column = styled.div`
  > ${BoxGroup}:not(:last-child) {
    margin-bottom: var(--box-group-not-last-margin-bottom);
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  text-align: center;
  align-self: flex-start;
  width: 100%;
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 700;
`

const BoxTop = styled.div`
  height: var(--box-top-height);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;

  &.type-T {
    background-color: ${theme.colors.secondary.orange};
  }

  &.type-B {
    background-color: ${theme.colors.secondary.yellow};
  }

  &.type-P {
    background-color: ${theme.colors.primary.blue};
    color: #ffffff;
  }

  &.type-L {
    background-color: ${theme.colors.secondary.green};
  }

  &.type-unavailable {
    background-color: ${theme.colors.grey.grey};
  }
`

const BoxBottom = styled.div`
  background-color: rgba(116, 192, 69, 0.3);
  height: var(--box-bottom-height);
  display: flex;
  align-items: center;
  justify-content: center;

  &.type-T {
    background-color: rgba(252, 177, 25, 0.3);
  }

  &.type-B {
    background-color: rgba(253, 206, 13, 0.3);
  }

  &.type-P {
    background-color: rgba(5, 133, 200, 0.3);
  }

  &.type-L {
    background-color: rgba(116, 192, 69, 0.3);
  }

  &.type-unavailable {
    background-color: rgba(210, 210, 210, 0.3);
    font-weight: 400;
  }
`

const LevelBox = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${theme.colors.grey.textDark};
  line-height: 28px;
  margin-right: 40px;
  padding-top: 40px;
  align-self: flex-start;
  opacity: 0.5;

  @media (max-width: 1023px) {
    margin: 15px 0;
    text-align: center;
    padding: 0;
    opacity: 1;

    &.type-T {
      color: ${theme.colors.secondary.orange};
    }

    &.type-B {
      color: ${theme.colors.secondary.yellow};
    }

    &.type-P {
      color: ${theme.colors.primary.blue};
    }

    &.type-L {
      color: ${theme.colors.secondary.green};
    }
  }
`

const DestinationLink = styled(UnstyledLink)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 700;
  color: ${theme.colors.grey.textDark};
  line-height: 14px;
  flex: 1;
  text-align: center;

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const NoMovement = styled.div`
  background-color: ${theme.colors.grey.greyBackground};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 120px;
`

const MobileOr = styled.div`
  display: none;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: ${theme.colors.grey.text};
  opacity: 0.6;
  margin-bottom: 12px;

  @media (max-width: 1023px) {
    display: block;
  }
`

const LevelPotentialMovement = ({
  potentialMovement,
  jobFamilyGroupName,
  jobFamilyName,
}) => {
  const { destinations } = potentialMovement

  const calcLineVerticalHeight = boxes => {
    if (boxes === 0) {
      return null
    }

    let noJobBoxCount = 0
    let jobBoxCount = 0

    boxes.forEach(box => {
      if (box.jobName) {
        jobBoxCount += 1
      } else {
        noJobBoxCount += 1
      }
    })

    const halfBoxTopHeight = "(var(--box-top-height) / 2)"

    const noJobBoxCalcStr = `${halfBoxTopHeight} + var(--box-group-not-last-margin-bottom) + ${halfBoxTopHeight} + 1px`
    const jobBoxCalcStr = `${noJobBoxCalcStr} + var(--box-bottom-height)`

    const additionalNoJobBoxCalcStr = `(${halfBoxTopHeight} + var(--box-group-not-last-margin-bottom) + ${halfBoxTopHeight}) *
    ${noJobBoxCount - 2}`
    const additionalBoxCalcStr = `(${halfBoxTopHeight} + var(--box-bottom-height) + var(--box-group-not-last-margin-bottom) + ${halfBoxTopHeight}) *
      ${jobBoxCount - 2}`

    const tmpCalcList = []

    if (noJobBoxCount === 2) {
      tmpCalcList.push(noJobBoxCalcStr)
    } else if (noJobBoxCount >= 3) {
      tmpCalcList.push(noJobBoxCalcStr, additionalNoJobBoxCalcStr)
    }

    if (jobBoxCount === 2) {
      tmpCalcList.push(jobBoxCalcStr)
    } else if (jobBoxCount >= 3) {
      tmpCalcList.push(jobBoxCalcStr, additionalBoxCalcStr)
    }

    return tmpCalcList.length > 0 ? `calc(${tmpCalcList.join(" + ")})` : "1px"
  }

  const SourceColumn = ({ jobFamilyName, jobName, noSourceJob = false }) => {
    return (
      <Column>
        <BoxGroup>
          <Box>
            <BoxTop
              className={`type-${
                noSourceJob
                  ? "unavailable"
                  : potentialMovement.jobLevel.bandShortForm
              }`}
            >
              {jobFamilyName}
            </BoxTop>

            <BoxBottom
              className={`type-${
                noSourceJob
                  ? "unavailable"
                  : potentialMovement.jobLevel.bandShortForm
              }`}
            >
              {noSourceJob
                ? "No jobs currently mapped to this job family"
                : jobName}
            </BoxBottom>
          </Box>

          <Arrow>
            <LineHorizontal />
          </Arrow>
        </BoxGroup>
      </Column>
    )
  }

  const DestinationColumn = ({ box, isFirstItem }) => {
    const foundJobFamily = getAllJobFamilies().find(
      jf => jf.slug === box.jobFamilySlug
    )

    return (
      <BoxGroup>
        <ArrowWithHead className={isFirstItem ? "" : "not-first-item"}>
          <LineHorizontal />
          <ArrowRight />
        </ArrowWithHead>
        {!isFirstItem && <MobileOr>Or</MobileOr>}
        <DestinationLink
          to={getJobFamilyUrl(
            getJobFamilyGroupSlugFromJobFamilySlug(
              box.jobFamilySlug,
              JOB_FAMILY_GROUPS
            ),
            box.jobFamilySlug
          )}
        >
          <BoxTop
            className={`type-${
              potentialMovement.jobLevel
                ? potentialMovement.jobLevel.bandShortForm
                : "P"
            }`}
          >
            {foundJobFamily.name}
          </BoxTop>
          {box.jobName && (
            <BoxBottom
              className={`type-${
                potentialMovement.jobLevel
                  ? potentialMovement.jobLevel.bandShortForm
                  : "P"
              }`}
            >
              {box.jobName}
            </BoxBottom>
          )}
        </DestinationLink>
      </BoxGroup>
    )
  }

  const destinationBoxes = destinations.reduce((acc, cur) => {
    if (cur.jobNames.length > 0) {
      return [
        ...acc,
        ...cur.jobNames.map(name => {
          return { jobFamilySlug: cur.jobFamilySlug, jobName: name }
        }),
      ]
    }
    return [...acc, { jobFamilySlug: cur.jobFamilySlug, jobName: null }]
  }, [])

  if (!potentialMovement.source.jobNames.length) {
    //  There may or may not be jobLevel value
    return (
      <React.Fragment>
        {potentialMovement.jobLevel ? (
          <LevelBox
            className={`type-${potentialMovement.jobLevel.bandShortForm}`}
          >
            {potentialMovement.jobLevel.bandShortForm}
            {potentialMovement.jobLevel.level}
          </LevelBox>
        ) : (
          <LevelBox className="type-unavailable">P</LevelBox>
        )}
        <SourceColumn
          jobName=""
          jobFamilyName={jobFamilyName}
          noSourceJob={true}
        />

        <Arrow>
          <LineVertical lineHeight={calcLineVerticalHeight(destinationBoxes)} />
        </Arrow>

        <Column>
          {destinationBoxes.map((box, boxIndex) => {
            return (
              <DestinationColumn
                key={`destination-column-${boxIndex}`}
                isFirstItem={boxIndex === 0}
                box={box}
              />
            )
          })}
        </Column>
      </React.Fragment>
    )
  }

  return potentialMovement.source.jobNames.map((srcJobName, index) => {
    return (
      <React.Fragment key={`source-job-${index}`}>
        {index === 0 ? (
          <LevelBox
            className={`type-${
              potentialMovement.jobLevel
                ? potentialMovement.jobLevel.bandShortForm
                : "P"
            }`}
          >
            {potentialMovement.jobLevel.bandShortForm}
            {potentialMovement.jobLevel.level}
          </LevelBox>
        ) : (
          <div></div>
        )}
        <SourceColumn jobName={srcJobName} jobFamilyName={jobFamilyName} />

        <Arrow>
          <LineVertical lineHeight={calcLineVerticalHeight(destinationBoxes)} />
        </Arrow>

        <Column>
          {destinationBoxes.map((box, boxIndex) => {
            return (
              <DestinationColumn
                key={`destination-column-${index}-${boxIndex}`}
                isFirstItem={boxIndex === 0}
                box={box}
              />
            )
          })}
        </Column>
      </React.Fragment>
    )
  })
}

const PotentialMovementModule = props => {
  const { potentialMovements, jobFamilyGroupName, name } = props
  const jobFamilyName = name

  const hasMovement = potentialMovements.some(pm => pm.destinations.length)

  return (
    <Wrapper>
      {potentialMovements.map((pm, i) => {
        return pm.destinations.length ? (
          <Grid
            className={`type-${pm.jobLevel ? pm.jobLevel.bandShortForm : "P"}`}
            key={`pm-${i}-${pm.jobLevel ? pm.jobLevel.toString() : ""}`}
          >
            <LevelPotentialMovement
              potentialMovement={pm}
              jobFamilyGroupName={jobFamilyGroupName}
              jobFamilyName={jobFamilyName}
            />
          </Grid>
        ) : null
      })}
      {!hasMovement && (
        <NoMovement>No potential movements at this time</NoMovement>
      )}
    </Wrapper>
  )
}

export default PotentialMovementModule

import React from "react"
import styled from "styled-components"

import { Box as MuiBox } from "@material-ui/core"

import theme from "@assets/styles/theme"

import { Tooltip } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

import HeaderBg from "@assets/job-family-group/banner_job_family.jpg"
import HeaderBgMobile from "@assets/job-family-group/banner_job_family_mobile.jpg"
import BulletGreen from "@assets/styles/bullet-green.svg"

import PotentialMovement from "@components/modules/PotentialMovement"
import AppLayout from "@components/layout/App"

import SKILLS_DATA from "@content/skills"

const PAGE_HEADER_HEIGHT = "236px"
const PAGE_GREY_HEIGHT = "156px"

const PageHeader = styled.div`
  background-image: url(${HeaderBg});
  background-size: cover;
  background-position: center;
  height: ${PAGE_HEADER_HEIGHT};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;

  @media (max-width: 1023px) {
    background-image: url(${HeaderBgMobile});
    height: auto;
  }
`

const PageHeaderBoxes = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;

  @media (max-width: 1023px) {
    display: none;
  }
`

const PageHeaderGrey = styled.div`
  background-color: rgba(63, 63, 63, 0.9);
  color: ${theme.colors.grey.white};
  flex: 1;
  height: ${PAGE_GREY_HEIGHT};

  overflow: hidden;

  @media (max-width: 1023px) {
    background-color: transparent;
    padding: 0;
  }
`

const PageHeaderBlue = styled.div`
  background-color: ${theme.colors.primary.blueDark};
  width: 40%;
  height: 60px;

  @media (max-width: 1023px) {
    display: none;
  }
`

const PageHeaderTextContainer = styled.div`
  color: ${theme.colors.grey.white};
  width: min(1140px, calc(100vw - 32px));
  padding: 24px 0;
  z-index: 2;
  height: ${PAGE_GREY_HEIGHT};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1023px) {
    width: 100%;
    text-align: center;
    align-items: center;
    padding: 0;
    height: auto;
  }
`

const PageHeaderH2 = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 0 8px 0;
  max-width: 50%;

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 21px;
    margin: 20px 0 4px 0;
    max-width: none;
  }
`

const PageHeaderBody = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;

  @media (max-width: 1023px) {
    padding-bottom: 16px;
    font-size: 12px;
    line-height: 20px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;

  @media (max-width: 1023px) {
    padding: 24px 16px 32px 16px;
  }
`

const PageGrid = styled.div`
  display: grid;
  width: min(1140px, calc(100vw - 32px));
  grid-template-columns: 1fr 32%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "description skills"
    "movement skills";
  column-gap: 36px;

  @media (max-width: 1023px) {
    width: 100%;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "description"
      "skills"
      "movement";
  }
`

const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: description;
  padding: 16px 0 24px 0;
  border-bottom: 1px solid ${theme.colors.grey.grey};
`

const PotentialMovementSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  grid-area: movement;
`

const SubHeader = styled.p`
  margin: 0 0 16px 0;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: ${props => props.theme.colors.primary.blue};

  @media (max-width: 1023px) {
    text-align: center;
  }
`

const SubCol = styled.div`
  grid-area: skills;

  border-top: solid 3px ${props => props.theme.colors.primary.blueDark};
  background-color: ${props => props.theme.colors.primary.blueLightest};

  @media (max-width: 1023px) {
    margin-left: 0;
    border-top: 0;
    border-bottom: 1px solid ${theme.colors.grey.grey};
    background-color: transparent;
  }

  .skills-wrapper {
    padding: 16px 24px;

    @media (max-width: 1023px) {
      padding: 16px 0;
    }
  }
`

const SubColHeader = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 1023px) {
    color: ${props => props.theme.colors.primary.blue};
    text-align: center;
    margin-top: 0;
  }
`

const SkillsPanel = styled.div`
  flex: 0 0 50%;
  margin-top: 24px;

  @media (max-width: 1023px) {
    margin: 0;
    padding: 8px 0;
  }

  .header {
    color: #666;
    font-size: 0.825rem;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }

  .skill {
    display: block;
    text-decoration: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    background: ${props => props.theme.colors.primary.blueDark};
    color: ${props => props.theme.colors.grey.white};
    border-radius: 20px;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;

    cursor: pointer;
  }

  &.mobile {
    flex: 0 0 100%;
  }
`

const SkillsTooltip = withStyles({
  tooltip: {
    color: theme.colors.grey.white,
    backgroundColor: theme.colors.grey.textDark,
  },
  arrow: {
    color: theme.colors.grey.textDark, // same as tooltip background color
  },
})(Tooltip)

const TooltipHeader = styled.div`
  margin-bottom: 8px;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`

const TooltipContent = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const BulletUl = styled.ul`
  list-style-image: url(${BulletGreen});

  margin: 0;
  padding-inline-start: 16px;
  margin-block-end: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 1023px) {
    padding-inline-start: 6px;
  }

  > li {
    padding-left: 12px;
  }

  > li:not(:last-child) {
    margin-bottom: 1em;
  }
`

const MobileScrollMenu = styled.div`
  background-color: ${theme.colors.grey.greyBackground};
  padding: 10px 0;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  position: sticky;
  top: var(--mobile-menu-height);
  z-index: 2;

  & > *:not(:first-child) {
    margin-left: 24px;
  }

  @media (max-width: 1023px) {
    display: flex;
  }

  & div {
    cursor: pointer;
  }

  & .active {
    font-weight: 700;
  }
`

const DesktopInstructions = styled.div`
  margin-top: 8px;
  color: ${theme.colors.grey.black};
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  @media (max-width: 1023px) {
    display: none;
  }
`
const MobileInstructions = styled.div`
  margin-top: 8px;
  color: ${theme.colors.grey.black};
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 4px;
  text-align: center;
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`

const formatLevelText = levels => {
  if (levels.length) {
    const sequence = ["B", "T", "P", "L"]
    let outputString = ""
    sequence.forEach(letter => {
      if (levels.some(l => l.bandShortForm === letter)) {
        const levelText = levels.find(l => l.bandShortForm === letter).levelText
        if (outputString) {
          outputString += `, ${levelText}`
        } else {
          outputString += ` ${levelText}`
        }
      }
    })
    return outputString
  }
  return " N/A"
}

const JobPage = props => {
  const { pageContext } = props

  const scrollOffset = 90
  const topOffset = 70

  const sections = [
    {
      name: "Description",
      scrollspy: "description",
    },
    {
      name: "Top Skills",
      scrollspy: "top-skills",
    },
    {
      name: "Potential Movements",
      scrollspy: "potential-movements",
    },
  ]

  const [activeSection, setActiveSection] = React.useState("description")

  React.useEffect(() => {
    const handleScroll = () => {
      function isInViewport(el) {
        const { top } = el.getBoundingClientRect()
        const { innerHeight } = window
        return top > topOffset && top < innerHeight
      }

      ;[...sections].reverse().forEach(section => {
        const el = document.querySelector(
          `[data-scroll-spy='${section.scrollspy}']`
        )
        if (isInViewport(el)) {
          setActiveSection(section.scrollspy)
        }
      })
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  const handleScrollMenuClick = section => {
    const selector = `[data-scroll-spy="${section}"]`
    const el = document.querySelector(selector)

    if (el) {
      // use scrollTo(left, top) instead of option for IE
      window.scrollTo(0, el.offsetTop - scrollOffset)
    }
  }

  const getDesktopSkillPanel = skills => {
    return (
      <SkillsPanel>
        {skills.map((skill, index) => {
          let foundSkill = SKILLS_DATA.find(s => s.name === skill)

          if (!foundSkill) {
            // in case there is any unmatched
            // console.log("skill definition not found:", skill)
            foundSkill = { definition: `${skill} definition not found` }
          }

          const TooltipTitle = (
            <MuiBox p={2}>
              <TooltipHeader>{skill}</TooltipHeader>
              <TooltipContent>{foundSkill.definition}</TooltipContent>
            </MuiBox>
          )

          return (
            <SkillsTooltip title={TooltipTitle} key={skill} arrow>
              <span className="skill">{skill}</span>
            </SkillsTooltip>
          )
        })}
      </SkillsPanel>
    )
  }

  return (
    <AppLayout>
      <PageHeader>
        <PageHeaderBoxes>
          <PageHeaderGrey />
          <PageHeaderBlue />
        </PageHeaderBoxes>
        <PageHeaderTextContainer>
          <PageHeaderH2>{pageContext.name}</PageHeaderH2>
          <PageHeaderBody>
            {pageContext.jobFamilyGroupName} | Levels:
            {formatLevelText(pageContext.jobLevels)}
          </PageHeaderBody>
        </PageHeaderTextContainer>
      </PageHeader>
      <MobileScrollMenu>
        {sections.map(s => {
          return (
            <div
              className={activeSection === s.scrollspy ? "active" : ""}
              key={`mobile-menu-${s.scrollspy}`}
              onClick={() => handleScrollMenuClick(s.scrollspy)}
            >
              {s.name}
            </div>
          )
        })}
      </MobileScrollMenu>
      <Content>
        <PageGrid>
          <DescriptionSection data-scroll-spy="description">
            <SubHeader>Job Family Description</SubHeader>
            <BulletUl>
              {pageContext.descriptions.map((jd, i) => (
                <li key={`description-item-${i}`}>{jd}</li>
              ))}
            </BulletUl>
          </DescriptionSection>
          <PotentialMovementSection data-scroll-spy="potential-movements">
            <SubHeader>Potential Career Movements</SubHeader>

            <PotentialMovement {...pageContext} />
          </PotentialMovementSection>

          <SubCol>
            <div data-scroll-spy="top-skills">
              <MuiBox className="skills-wrapper">
                <SubColHeader>Top Skills</SubColHeader>
                <DesktopInstructions>
                  Hover on any of the Skills below to view a detailed
                  description
                </DesktopInstructions>
                <MobileInstructions>
                  Tap on any of the Skills below to view a detailed description
                </MobileInstructions>

                {getDesktopSkillPanel(pageContext.topSkills)}
              </MuiBox>
            </div>
          </SubCol>
        </PageGrid>
      </Content>
    </AppLayout>
  )
}

export default JobPage

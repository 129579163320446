export default [
  {
    name: "3D Modeling",
    slug: "3-d-modeling",
    definition:
      "The creation of two-dimensional or three-dimensional drawings of objects to plan, communicate, and assess a project, with 2D mainly for line drawings and 3D modeling for creating spaces and objects.",
  },
  {
    name: "Access Control Management",
    slug: "access-control-management",
    definition:
      "The management of who is authorized to access, view, and/or edit corporate data and resources.",
  },
  {
    name: "Account Planning",
    slug: "account-planning",
    definition:
      "A process of building value-driven relationships with your key customers that can help in long-term development and retention, thereby maximizing the revenue potential.",
  },
  {
    name: "Accounting Standards",
    slug: "accounting-standards",
    definition:
      "The ability to apply financial reporting framework prescribed by the relevant governing body to ensure all transactions meet regulatory requirements.",
  },
  {
    name: "Active Listening",
    slug: "active-listening",
    definition:
      "The process of listening and observing both verbal and non-verbal messages and giving responses that would improve mutual understanding.",
  },
  {
    name: "Agile Methodology",
    slug: "agile-methodology",
    definition:
      "Agile project management is an iterative approach to delivering a project throughout its life cycle, taking incremental steps towards the completion of a project.",
  },
  {
    name: "Alliance Management",
    slug: "alliance-management",
    definition:
      "The process of adding value to a company by extending its accounts to company partners.",
  },
  {
    name: "API Integration",
    slug: "api-integration",
    definition:
      "The development of message protocols that allow distributed elements of an application to communicate.",
  },
  {
    name: "Applicant Tracking Systems",
    slug: "applicant-tracking-systems",
    definition:
      "The management of software for recruiters and employers to track candidates throughout the recruiting and hiring process.",
  },
  {
    name: "Application Security",
    slug: "application-security",
    definition:
      "The improvement of the security of an application by finding, fixing, and preventing security vulnerabilities.",
  },
  {
    name: "Architecture Strategy",
    slug: "architecture-strategy",
    definition:
      "Defines the organization of a product's functional elements and how each element relates with one another.",
  },
  {
    name: "Artificial Intelligence",
    slug: "artificial-intelligence",
    definition:
      "The application of knowledge of artificial intelligence and machine learning from a data perspective and understanding potential data applications to improve overall business outcomes.",
  },
  {
    name: "Asset Life Cycle Management",
    slug: "asset-life-cycle-management",
    definition:
      "The process of optimizing an asset's reliability and operational performance throughout its lifetime, reducing the cost of maintenance and improving efficiency.",
  },
  {
    name: "Asset Management",
    slug: "asset-management",
    definition:
      "A systematic process of developing, operating, maintaining, upgrading, and disposing of assets in the most cost-effective manner, including all costs, risks, and performance attributes.",
  },
  {
    name: "Asset Protection",
    slug: "asset-protection",
    definition:
      "The process of preventing financial loss, theft, and fraud within a company.",
  },
  {
    name: "Audience Analysis",
    slug: "audience-analysis",
    definition:
      "The process of identifying the audience and adapting content to their interests, level of understanding, attitudes, and beliefs.",
  },
  {
    name: "Audience Segmentation",
    slug: "audience-segmentation",
    definition:
      "A marketing strategy based on identifying subgroups within the target audience to deliver more tailored messaging for stronger connections.",
  },
  {
    name: "Audit Management",
    slug: "audit-management",
    definition:
      "An independent, objective assurance, and consulting activity designed to add value and improve an organization's operations.",
  },
  {
    name: "Audit Testing",
    slug: "audit-testing",
    definition:
      "The practice of taking a small sample from a larger population to examine and evaluate services or products, policies, or operations, which are then extrapolated to the entire population.",
  },
  {
    name: "Auditing",
    slug: "auditing",
    definition:
      "An objective examination and evaluation of organizational processes to ensure compliance to requirements.",
  },
  {
    name: "Behavioral Based Safety",
    slug: "behavioral-based-safety",
    definition:
      "The process of identifying the immediate and root causes of unsafe behavior and then apply corrective measures to reduce unsafe actions by employees.",
  },
  {
    name: "Benchmarking",
    slug: "benchmarking",
    definition:
      "A process that involves defining the competitive market and measuring the performance of a business against a competitor that defined market.",
  },
  {
    name: "Benefits Administration",
    slug: "benefits-administration",
    definition:
      "The process of overseeing provision of monetary and non-monetary benefits.",
  },
  {
    name: "Billing",
    slug: "billing",
    definition:
      "The management of billing processes and systems and implement control to ensure that bills are processed correctly and in a timely manner.",
  },
  {
    name: "Brand Strategy",
    slug: "brand-strategy",
    definition:
      "The process of establishing a long-term plan for the development of a successful brand to achieve specific goals.",
  },
  {
    name: "Budget Management",
    slug: "budget-management",
    definition:
      "The process to oversee budget and finance systems and processes for tracking of budget utilization to ensure efficient and effective use of budgets.",
  },
  {
    name: "Business Acumen",
    slug: "business-acumen",
    definition:
      "Possesses knowledge of critical business concepts relevant to the business unit and demonstrates ability to use deep business knowledge to inform decision-making.",
  },
  {
    name: "Business Continuity Planning",
    slug: "business-continuity-planning",
    definition:
      "The process of developing internal infrastructure to ensure organizational resilience and maintenance of the availability, stability, and integrity of critical systems, processes, and stakeholders that support and drive key aspects of the business.",
  },
  {
    name: "Business Development",
    slug: "business-development",
    definition:
      "The process to identify and acquire new business opportunities that can create growth and profitability.",
  },
  {
    name: "Business Insights",
    slug: "business-insights",
    definition:
      "The process to set and implement strategies that balance commercial and people priorities across multiple functions by improving financial performance and managing business in a commercially viable way.",
  },
  {
    name: "Business Intelligence Reporting",
    slug: "business-intelligence-reporting",
    definition:
      "The process of using a Business Intelligence tool to prepare and analyze data to find and share actionable insights.",
  },
  {
    name: "Business Negotiations",
    slug: "business-negotiations",
    definition:
      "The planning, reviewing, analysis and other activities that a buyer and seller conduct to reach a mutually beneficial agreement.",
  },
  {
    name: "Business Process Improvements",
    slug: "business-process-improvements",
    definition:
      "The process of analyzing business processes and workflows within the organization and identifying new approaches to redesign business activities or optimize performance, quality, and speed of processes – including identifying and evaluating process automation opportunities.",
  },
  {
    name: "Business Productivity",
    slug: "business-productivity",
    definition:
      "The process of improving workplace productivity in a manner that helps employees and team members to work more efficiently.",
  },
  {
    name: "Business Strategies",
    slug: "business-strategies",
    definition:
      "The process of outlining actions and decisions a company plans to take to reach its goals and objectives.",
  },
  {
    name: "Campaign Development",
    slug: "campaign-development",
    definition:
      "The process of maintaining consistent brand messaging across numerous marketing channels including traditional and digital media.",
  },
  {
    name: "Candidates Management",
    slug: "candidates-management",
    definition:
      "The process of developing a strategy for the organization’s candidate experience and define essential components of the candidate experience to guide the enhancement of the overall candidate assessment process.",
  },
  {
    name: "Capital Markets",
    slug: "capital-markets",
    definition:
      "The process of allowing businesses to raise long-term funds by providing a market for securities, both through debt and equity.",
  },
  {
    name: "Case Management",
    slug: "case-management",
    definition:
      "A collaborative process of assessment, planning, facilitation and advocacy for options and services to meet an individual’s holistic needs through communication and available resources to promote quality cost-effective outcomes.",
  },
  {
    name: "Category Management",
    slug: "category-management",
    definition:
      "The process of bundling similar products into a singular category, or business unit, and then addressing procurement, merchandising, sales, and other retail efforts on the category as a whole.",
  },
  {
    name: "Category Strategy",
    slug: "category-strategy",
    definition:
      "The process of defining what a category of goods or services needs to be to maximize efficiencies in the supply chain.",
  },
  {
    name: "Change Management",
    slug: "change-management",
    definition:
      "The process of controlled identification, planning and implementation of required initiatives to prepare, support and help individuals, teams and organizations to navigate through change.",
  },
  {
    name: "Claims Adjudication",
    slug: "claims-adjudication",
    definition:
      "The process of reviewing claims and deciding to pay in full, partially, or deny the claim altogether.",
  },
  {
    name: "Claims Evaluation",
    slug: "claims-evaluation",
    definition:
      "The process of making a decision or benefits determination in a claim case.",
  },
  {
    name: "Claims Management",
    slug: "claims-management",
    definition:
      "The process of providing advice or services in respect to claims for compensation, restitution, repayment or any other remedy for loss or damage, or in respect of some other obligation.",
  },
  {
    name: "Cloud Architectures",
    slug: "cloud-architectures",
    definition:
      "The technology components that are combined to build a cloud, where resources are pooled through virtualization technology and shared across a network.",
  },
  {
    name: "Cloud Computing",
    slug: "cloud-computing",
    definition:
      "The on-demand availability of computer system resources, especially data storage and computing power, without direct active management by the user.",
  },
  {
    name: "Cloud Networking",
    slug: "cloud-networking",
    definition:
      "The process of giving users access to networking resources through a centralized third-party provider operating inter-connected servers.",
  },
  {
    name: "Collective Bargaining",
    slug: "collective-bargaining",
    definition:
      "The process in which employees, through their unions, negotiate contracts with their employers to determine their terms of employment, including pay, benefits, hours, leave, job health and safety policies, ways to balance work and family, and more.",
  },
  {
    name: "Communication Management",
    slug: "communication-management",
    definition:
      "The systematic planning, implementation, monitoring, and revision of communications through the use of various communication tools and channels.",
  },
  {
    name: "Community Outreach",
    slug: "community-outreach",
    definition:
      "The process of building an authentic community among a business' customers, employees, and partners through various types of interaction.",
  },
  {
    name: "Company Representation",
    slug: "company-representation",
    definition:
      "The process of representing and communicating the organization’s projects and initiatives to external stakeholders.",
  },
  {
    name: "Compensation Administration",
    slug: "compensation-administration",
    definition: "The process of overseeing the payment of employees.",
  },
  {
    name: "Compensation Strategies",
    slug: "compensation-strategies",
    definition:
      "The process of setting, managing, and evolving the strategy on how the organization approaches compensation, including setting salary ranges and determining how raises and bonuses are calculated.",
  },
  {
    name: "Compensation Structures",
    slug: "compensation-structures",
    definition:
      "The process of creating, managing, and regularly evaluating the system that is used to determine how each employee in an organization is paid while also communicating this to employees and job candidates.",
  },
  {
    name: "Competitive Intelligence",
    slug: "competitive-intelligence",
    definition:
      "The ability to gather, analyze, and use information collected on competitors, customers, and other market factors that contribute to a business's competitive advantage. Sometimes referred to as corporate intelligence.",
  },
  {
    name: "Competitive Market Analysis",
    slug: "competitive-market-analysis",
    definition:
      "The process of identifying competitors and evaluating their strategies to determine their strengths and weaknesses.",
  },
  {
    name: "Complaints Management",
    slug: "complaints-management",
    definition:
      "The process of conducting, investigating, and resolving any complaints or feedback.",
  },
  {
    name: "Computer-Aided Design (CAD)",
    slug: "computer-aided-design-cad",
    definition:
      "The use of computers / software to aid in the creation, modification, analysis, or optimization of a design.",
  },
  {
    name: "Condition Monitoring",
    slug: "condition-monitoring",
    definition:
      "The process of monitoring the functionality of a particular condition in equipment to identify changes that could indicate a developing fault.",
  },
  {
    name: "Configuration Management",
    slug: "configuration-management",
    definition:
      "The process of tracking and controlling changes in the software configuration, including revision control and the establishment of baselines.",
  },
  {
    name: "Conflict Management",
    slug: "conflict-management",
    definition:
      "The process by which disputes are resolved, where negative results are minimized and positive results are prioritized.",
  },
  {
    name: "Construction Management",
    slug: "construction-management",
    definition:
      "The use of specialized project management techniques to plan, design and complete a construction project.",
  },
  {
    name: "Consulting",
    slug: "consulting",
    definition:
      "The ability to develop and execute solutions to business challenges and opportunities and to influence strategic outcomes.",
  },
  {
    name: "Content Creation",
    slug: "content-creation",
    definition:
      "The process of generating topic ideas that appeal to your buyer persona and creating written or visual content around those.",
  },
  {
    name: "Content Curation",
    slug: "content-curation",
    definition:
      "The process of sourcing, selecting, prioritizing, and organizing learning offerings to provide the most relevant and easy to use content to meet the organization's learning needs",
  },
  {
    name: "Content Development",
    slug: "content-development",
    definition:
      "The entire process of creating content to achieve specific business goals, including idea generation, topic research, keyword research, writing, and publishing.",
  },
  {
    name: "Content Writing",
    slug: "content-writing",
    definition:
      "The process of producing engaging content for use online, such as creation of articles, blog posts, and other forms of written web material.",
  },
  {
    name: "Continual Improvement Process",
    slug: "continual-improvement-process",
    definition:
      "An ongoing effort to evaluate and improve products, services, or processes through incremental and breakthrough improvements.",
  },
  {
    name: "Contract Administration",
    slug: "contract-administration",
    definition:
      "The process of supervising the contract lifecycle and the steps taken to achieve the desired business outcomes.",
  },
  {
    name: "Contract Lifecycle Management",
    slug: "contract-lifecycle-management",
    definition:
      "The process of tracking and managing every aspect of a contract for its performance, compliance, and other success factors through every stage of the document's lifecycle — from execution to renewal or expiration.",
  },
  {
    name: "Contract Management",
    slug: "contract-management",
    definition:
      "Management of the creation, execution, storage or renewal of contracts, with a focus on compliance.",
  },
  {
    name: "Contractor Management",
    slug: "contractor-management",
    definition:
      "The managing of outsourced work performed for an individual company.",
  },
  {
    name: "Contracts Formation",
    slug: "contracts-formation",
    definition:
      "The establishment of a legal agreement between two or more parties whereby one party has set out contractual terms that are mostly non-negotiable, providing a level of consistency and predictability to terms and conditions.",
  },
  {
    name: "Corporate Financial Reporting",
    slug: "corporate-financial-reporting",
    definition:
      "A standard accounting practice that uses financial statements to disclose a company's financial information and performance over a particular period, usually on an annual or quarterly basis.",
  },
  {
    name: "Corporate Governance",
    slug: "corporate-governance",
    definition:
      "The collection of mechanisms, processes and relations used by various parties to control and to operate a corporation.",
  },
  {
    name: "Corrective Maintenance",
    slug: "corrective-maintenance",
    definition:
      "A series of technical tasks aimed at correcting faults in equipment that needs repair or replacement, while also aiming to prevent the occurrence of faults and malfunctions in equipment before they happen.",
  },
  {
    name: "Cost Analysis",
    slug: "cost-analysis",
    definition:
      "A process businesses use to analyze decisions, whereby a business sums the benefits of a situation or action and then subtracts the costs associated with taking that action.",
  },
  {
    name: "Cost Management",
    slug: "cost-management",
    definition:
      "The process of estimating, allocating, and controlling costs, which includes collecting, analyzing, and reporting cost information to more effectively budget, forecast, and monitor costs.",
  },
  {
    name: "Cost Optimization",
    slug: "cost-optimization",
    definition:
      "The process to analyze, plan, and manage costs for cost efficiency and expense reduction, while maximizing business value.",
  },
  {
    name: "Credit Risk Management",
    slug: "credit-risk-management",
    definition:
      "The process of establishing the organization's financial and credit policies and frameworks to identify, assess and manage the organization's credit risk exposure.",
  },
  {
    name: "Crisis Communication Management",
    slug: "crisis-communication-management",
    definition:
      "The process of managing communications with the media and/or relevant stakeholders during crises to maintain a positive image of the organization.",
  },
  {
    name: "Crisis Management",
    slug: "crisis-management",
    definition:
      "The process of identifying and dealing with disruptive and unexpected events that threaten to harm the organization or its stakeholders.",
  },
  {
    name: "Critical Incident Training",
    slug: "critical-incident-training",
    definition:
      "The process of performing a security mock drill or cyber attack simulation exercise to practice and evaluate how an organization would respond to an unexpected event.",
  },
  {
    name: "Curriculum Development",
    slug: "curriculum-development",
    definition:
      "The multi-step process of creating and improving courses. The process can be categorized into five basic steps: 1) needs assessment, 2) planning, 3) content development, 4) pilot delivery and revision, and 5) the completed curriculum package.",
  },
  {
    name: "Customer Experience Management",
    slug: "customer-experience-management",
    definition:
      "The practice of designing and reacting to customer interactions to meet or exceed customer expectations, thereby increasing customer satisfaction, loyalty and advocacy.",
  },
  {
    name: "Customer Journey Mapping",
    slug: "customer-journey-mapping",
    definition:
      "The process of using storytelling and visuals to illustrate the relationship a customer has with a business over a period of time.",
  },
  {
    name: "Customer Needs",
    slug: "customer-needs",
    definition:
      "A structured, methodical approach to understanding customers' relationship with, and perception of, an organization's products or services and its brand.",
  },
  {
    name: "Customer Relationship Management",
    slug: "customer-relationship-management",
    definition:
      "The process for managing a company’s relationships and interactions with customers and potential customers.",
  },
  {
    name: "Customer Service",
    slug: "customer-service",
    definition:
      "The process of anticipating buyers’ needs or problems before they are aware of them and actively reaching out with a solution.",
  },
  {
    name: "Customer Service Management",
    slug: "customer-service-management",
    definition:
      "The process of supporting customers both before and after they buy and use products or services that helps them have a positive experience.",
  },
  {
    name: "Customer Success Management",
    slug: "customer-success-management",
    definition:
      "The process to ensure customers achieve their desired business outcomes when using an organization's products or services through client relationship management.",
  },
  {
    name: "Cybersecurity Risk Management",
    slug: "cybersecurity-risk-management",
    definition:
      "The process of developing cyber risk assessment and treatment techniques that can effectively pre-empt and identify significant security loopholes and weaknesses, demonstrating the business risks associated with these loopholes and providing risk treatment and prioritization strategies to effectively address the cyber-related risks, threats and vulnerabilities, ensuring appropriate levels of protection, confidentiality, integrity and privacy in alignment with the security framework",
  },
  {
    name: "Cybersecurity Strategies",
    slug: "cybersecurity-strategies",
    definition:
      "The process of identifying cyber security requirements and associated plans and roadmaps to achieve them, while also working to identify and mitigate risks to systems and data and defining cyber security objectives and controls for the organization.",
  },
  {
    name: "Data Analysis",
    slug: "data-analysis",
    definition:
      "The process of measuring and managing organizational data, identifying methodological best practices and conducting statistical analyses.",
  },
  {
    name: "Data Architecture",
    slug: "data-architecture",
    definition:
      "A framework for how IT infrastructure supports an organization’s data strategy. The goal of any data architecture is to show the company's infrastructure how data is acquired, transported, stored, queried, and secured.",
  },
  {
    name: "Data Availability",
    slug: "data-availability",
    definition:
      "The process of ensuring that data is available to end-users and applications, when and where they need it.",
  },
  {
    name: "Data Governance",
    slug: "data-governance",
    definition:
      "The determination and implementation of a set of practices and processes for managing important data across an organization.",
  },
  {
    name: "Data Integration",
    slug: "data-integration",
    definition:
      "The process of combining data residing in different sources and providing users with a unified view of them.",
  },
  {
    name: "Data Integrity",
    slug: "data-integrity",
    definition:
      "The process of maintaining and assuring data accuracy and consistency over its entire life-cycle.",
  },
  {
    name: "Data Interpretations",
    slug: "data-interpretations",
    definition:
      "The process of using diverse analytical methods to review data and arrive at relevant conclusions.",
  },
  {
    name: "Data Management",
    slug: "data-management",
    definition:
      "A process which includes acquiring, validating, storing, protecting, and processing required data, ensuring the data is accurate and accessible.",
  },
  {
    name: "Data Migrations",
    slug: "data-migrations",
    definition:
      "The process of selecting, preparing, extracting, and transforming data and permanently transferring it from one computer storage system to another.",
  },
  {
    name: "Data Mining",
    slug: "data-mining",
    definition:
      "A process of extracting and discovering patterns in large data sets involving methods at the intersection of machine learning, statistics, and database systems.",
  },
  {
    name: "Data Modeling",
    slug: "data-modeling",
    definition:
      "The process of creating a data model for data storage in a database, managing a data model, setting model security, and managing model elements and security.",
  },
  {
    name: "Data Preparation",
    slug: "data-preparation",
    definition:
      "The process of gathering, combining, structuring and organizing data so it can be used in various business applications such as business intelligence (BI), analytics and data visualization.",
  },
  {
    name: "Data Quality Management",
    slug: "data-quality-management",
    definition:
      "The utilization of processes, methods, and technologies to ensure the quality of the data meets specific business requirements.",
  },
  {
    name: "Data Storage",
    slug: "data-storage",
    definition:
      "The management of software and processes that improve the performance of data storage resources. It may include network visualization, replication, mirroring, security, compression, deduplication, traffic analysis, process automation, storage provisioning, and memory management.",
  },
  {
    name: "Data Storytelling",
    slug: "data-storytelling",
    definition:
      "The process of effectively communicating insights from a dataset using narratives and visualizations.",
  },
  {
    name: "Data Visualization",
    slug: "data-visualization",
    definition:
      "An interdisciplinary field that deals with the graphic representation of data, highlighting patterns and trends in the data to allow for quick insights.",
  },
  {
    name: "Data Warehousing",
    slug: "data-warehousing",
    definition:
      "The process of collecting data from a wide range of sources within a company into a single database that may be used to guide management decisions.",
  },
  {
    name: "Database Administration",
    slug: "database-administration",
    definition:
      "The process of creating new or updating existing databases based on data collection needs.",
  },
  {
    name: "Database Design",
    slug: "database-design",
    definition:
      "A collection of processes that facilitate the designing, development, implementation and maintenance of enterprise data management systems.",
  },
  {
    name: "Database Management Systems",
    slug: "database-management-systems",
    definition:
      "The function of managing and maintaining database management systems (DBMS) software, designed to define, manipulate, retrieve and manage data in a database.",
  },
  {
    name: "Database Storage",
    slug: "database-storage",
    definition:
      "The process of monitoring, allocating and conserving space on a database to optimize performance and to ensures database access and capacity for growth/scaling.",
  },
  {
    name: "Demand Forecasting",
    slug: "demand-forecasting",
    definition:
      "The process of estimating the quantity of goods and services the organization realistically expects sell over the forecast period.",
  },
  {
    name: "Demand Planning",
    slug: "demand-planning",
    definition:
      "The process for forecasting the demand for a product or service so it can be delivered more efficiently and to the satisfaction of end users / customers.",
  },
  {
    name: "Design Engineering",
    slug: "design-engineering",
    definition:
      "The process of developing conceptual and detailed designs that ensure a product functions, performs, and is fit for its purpose.",
  },
  {
    name: "Design Specifications",
    slug: "design-specifications",
    definition:
      "The determination of technical specifications that provide a common design for a product, process, service, or system.",
  },
  {
    name: "Destructive Testing",
    slug: "destructive-testing",
    definition:
      "The process of conducting tests carried out to the specimen’s failure, to understand a specimen’s performance or material behavior under different loads.",
  },
  {
    name: "DevOps",
    slug: "dev-ops",
    definition:
      "A set of practices that combines software development and information-technology operations which aims to shorten the systems development life cycle and provide continuous delivery with high software quality.",
  },
  {
    name: "DevSecOps",
    slug: "dev-sec-ops",
    definition:
      "Short for development, security, and operations—automates the integration of security at every phase of the software development lifecycle, from initial design through integration, testing, deployment, and software delivery.",
  },
  {
    name: "Digital Marketing",
    slug: "digital-marketing",
    definition:
      "The process of marketing to consumers through any number of digital channels, including websites, mobile devices, and social media platforms.",
  },
  {
    name: "Digital Treasury",
    slug: "digital-treasury",
    definition:
      "The process of using secure, advanced, and cost-efficient technology and processes to accomplish treasury tasks to minimize manual manipulation, and automates and expedites decision making.",
  },
  {
    name: "Disaster Recovery",
    slug: "disaster-recovery",
    definition:
      "The development and implementation of internal policies, processes and arrangements to guide and enable the prompt recovery of critical infrastructure and systems following a crisis or disaster. This includes monitoring the efficiency and effectiveness of response to significant incidents or disruptions and reviewing the organization's disaster recovery plan and processes.",
  },
  {
    name: "Distribution Automation",
    slug: "distribution-automation",
    definition:
      "The process of implementing an integrated solution of field apparatus, devices, communications and software applications designed to optimize power grid efficiency and reliability.",
  },
  {
    name: "Distribution Meter Installation",
    slug: "distribution-meter-installation",
    definition: "The process of installing energy distribution meters.",
  },
  {
    name: "Distribution Meter Maintenance and Repair",
    slug: "distribution-meter-maintenance-and-repair",
    definition:
      "The process of maintaining and repairing energy distribution meters.",
  },
  {
    name: "Distribution Meter Tuning",
    slug: "distribution-meter-tuning",
    definition:
      "The process of tuning energy distribution meters to optimize their operation.",
  },
  {
    name: "Drafting Documents",
    slug: "drafting-documents",
    definition:
      "The process of generating and drafting preliminary versions of contractual and legal documents.",
  },
  {
    name: "Economic Development Strategy",
    slug: "economic-development-strategy",
    definition:
      "A strategic planning process that helps guide economic growth based on economic analyses that focuses on economic impact, regional markets, and economic trends.",
  },
  {
    name: "Electric Distribution Systems Management",
    slug: "electric-distribution-systems-management",
    definition:
      "The process of advanced monitoring, analysis, control, optimization, planning, and training of distribution systems to provide more reliable, safe, and efficient power.",
  },
  {
    name: "Electrical Engineering",
    slug: "electrical-engineering",
    definition:
      "An engineering discipline concerned with the study, design and application of equipment, devices, and systems which deliver electricity.",
  },
  {
    name: "Emergency Response",
    slug: "emergency-response",
    definition:
      "The coordination and management of resources and responsibilities pertaining to the mitigation of, preparedness for, response to, and recovery from an emergency.",
  },
  {
    name: "Employee Benefits Design",
    slug: "employee-benefits-design",
    definition:
      "The design and implementation of agile, segmented, and flexible structures to enable adaptability for providing benefits of diverse talent groups aligned to organizational needs",
  },
  {
    name: "Employee Engagement",
    slug: "employee-engagement",
    definition:
      "The process of engaging employees with a view to long-term employee retention, higher levels of productivity, and improved quality of work.",
  },
  {
    name: "Employee Relationship Management",
    slug: "employee-relationship-management",
    definition:
      "The process of developing and maintaining both the individual and collective relationships in the workplace, which covers the contractual, practical, as well as the physical and emotional dimensions of the employee-employer relationship, especially when it comes to sensitive topics.",
  },
  {
    name: "Employer Branding",
    slug: "employer-branding",
    definition:
      "Implementation of a strategy that seeks to influence how current employees and the rest of the larger workforce perceive a company's brand and reputation.",
  },
  {
    name: "Energy Delivery Management",
    slug: "energy-delivery-management",
    definition:
      "The process of overseeing energy delivery systems necessary to achieve secure, reliable, and economic delivery of energy.",
  },
  {
    name: "Energy Distribution Systems Management",
    slug: "energy-distribution-systems-management",
    definition:
      "The process of coordinating and managing electricity generation, consumption, and transportation with the aim to optimize energy production and performance and achieve energy management goals.",
  },
  {
    name: "Energy Management",
    slug: "energy-management",
    definition:
      "The proactive, organized, and systematic management of energy use in a building or organization to satisfy both environmental and economic requirements.",
  },
  {
    name: "Energy Market Analysis",
    slug: "energy-market-analysis",
    definition:
      "The process of examining and evaluating regulated or deregulated energy markets that deal specifically with the trade and supply of energy.",
  },
  {
    name: "Energy Policy",
    slug: "energy-policy",
    definition:
      "The process of creating direction and guidance, approved by the government from time to time, for the development of energy resources.",
  },
  {
    name: "Energy Purchasing",
    slug: "energy-purchasing",
    definition:
      "The strategic process of sourcing, identifying, and acquiring energy contracts that best suit the needs of the business.",
  },
  {
    name: "Energy Settlement",
    slug: "energy-settlement",
    definition:
      "The process of reconciling the difference between the energy purchased by energy suppliers from generators /producers and the energy sold to customers.",
  },
  {
    name: "Energy Trading",
    slug: "energy-trading",
    definition:
      "The strategic approach taken to trading in the energy market to take advantage of fluctuations in price and volume when buying and selling energy commodities.",
  },
  {
    name: "Energy Transaction Management",
    slug: "energy-transaction-management",
    definition:
      "The process of managing the purchase or sale of a specified quantity of energy at a known transaction point or through an organized market.",
  },
  {
    name: "Engineering Design",
    slug: "engineering-design",
    definition:
      "An iterative process used to identify problems and develop and improve solutions.",
  },
  {
    name: "Engineering Drawings",
    slug: "engineering-drawings",
    definition:
      "The interpretation of engineering diagrams and understanding the information that they are intended to communicate.",
  },
  {
    name: "Enterprise Architecture Planning",
    slug: "enterprise-architecture-planning",
    definition:
      "The configuration of IT resources in service of an organization's business strategy, especially business roles and processes that create and use business data.",
  },
  {
    name: "Enterprise Risk Management",
    slug: "enterprise-risk-management",
    definition:
      "The process of identifying and addressing methodically the potential events that represent risks to the achievement of strategic objectives, or to opportunities to gain competitive advantage.",
  },
  {
    name: "Environmental Awareness",
    slug: "environmental-awareness",
    definition:
      "The understanding of the natural world at large including the fundamental understanding of basic biological, chemical, and ecological processes, especially natural environment protection and the activities that can cause harm to the environment.",
  },
  {
    name: "Environmental Impact Assessment",
    slug: "environmental-impact-assessment",
    definition:
      "The assessment of the environmental consequences of a plan, policy, program, or actual projects prior to the decision to move forward with the proposed action.",
  },
  {
    name: "Environmental Management Systems",
    slug: "environmental-management-systems",
    definition:
      "A systematic integrated approach to effectively managing environmental hazards and risks.",
  },
  {
    name: "Environmental Monitoring Techniques",
    slug: "environmental-monitoring-techniques",
    definition:
      "The process of assessing environmental conditions and trends, supporting policy development and its implementation, and developing information for reporting to national policymakers, international forums, and the public.",
  },
  {
    name: "Environmental Permitting",
    slug: "environmental-permitting",
    definition:
      "The process of defining — in a transparent, accountable manner — legally binding requirements to protect human health and the environment.",
  },
  {
    name: "Environmental Reports",
    slug: "environmental-reports",
    definition:
      "The process of providing a significant tool for environmental communication and fulfilling organizations' accountability in regard to environmental awareness.",
  },
  {
    name: "eProcurement",
    slug: "e-procurement",
    definition:
      "The process of applying technology to strategically automate the exchange of procurement information throughout the supply chain.",
  },
  {
    name: "Equipment Commissioning/Decommissioning",
    slug: "equipment-commissioning-decommissioning",
    definition:
      "Commissioning is the process of putting a piece of equipment into operation, whereas decommissioning is the shutting down or removal of equipment from service at its end of life.",
  },
  {
    name: "Equipment Installations",
    slug: "equipment-installations",
    definition:
      "The process of fitting equipment or placing it somewhere so that it is ready to be used, ensuring that all safety requirements and policies are being adhered to.",
  },
  {
    name: "Equipment Repair Maintenance",
    slug: "equipment-repair-maintenance",
    definition:
      "The process used to keep equipment reliable and in working order, including routine upkeep as well as corrective repair.",
  },
  {
    name: "Equipment Testing",
    slug: "equipment-testing",
    definition:
      "The process of testing equipment to ensure it is compatible, safe, and compliant with all requirements.",
  },
  {
    name: "Equity Compensation",
    slug: "equity-compensation",
    definition:
      "The process of evaluating and ensuring employees performing comparable work are receiving comparable compensation and that any differences in pay can be explained by legitimate job-related factors.",
  },
  {
    name: "Escalation Management",
    slug: "escalation-management",
    definition:
      "The process of following proper escalation protocol to manage incidents and ensure problems are resolved and addressed at the appropriate level.",
  },
  {
    name: "ESG Analysis",
    slug: "esg-analysis",
    definition:
      "The process of identifying ESG risks, assessing and benchmarking ESG maturity, and prioritizing strategies to drive new growth, efficiency, and resilience.",
  },
  {
    name: "Facility Management",
    slug: "facility-management",
    definition:
      "The process of conducting planned preventive and predictive maintenance as well as repairs to ensure that building systems perform as originally designed and constructed.",
  },
  {
    name: "Feasibility Studies",
    slug: "feasibility-studies",
    definition:
      "An assessment of the practicality of a proposed plan or project. A feasibility study analyzes the viability of a project to determine whether the project or venture is likely to succeed.",
  },
  {
    name: "Financial Accounting",
    slug: "financial-accounting",
    definition:
      "The process of recording, summarizing, and reporting a company's business transactions through financial statements.",
  },
  {
    name: "Financial Analysis",
    slug: "financial-analysis",
    definition:
      "The process of analyzing the financial statements and data to provide insights about the financial performance and position of the organization over time.",
  },
  {
    name: "Financial Auditing",
    slug: "financial-auditing",
    definition:
      "An objective examination and evaluation of the financial statements of an organization to make sure that the financial records are a fair and accurate representation of the transactions they claim to represent.",
  },
  {
    name: "Financial Management",
    slug: "financial-management",
    definition:
      "The process of handling a company’s finances in a way that allows it to be successful and compliant with regulators.",
  },
  {
    name: "Financial Modeling",
    slug: "financial-modeling",
    definition:
      "The process of developing financial and valuation models to arrive at a valuation conclusion.",
  },
  {
    name: "Financial Planning",
    slug: "financial-planning",
    definition:
      "The process of developing a comprehensive evaluation of an individual or organization’s current pay and future financial state by using current known variables to predict future income, asset values, and withdrawal plans. This often includes a budget which organizes finances and sometimes includes a series of steps or specific goals for spending and saving in the future.",
  },
  {
    name: "Financial Risk Management",
    slug: "financial-risk-management",
    definition:
      "The practice of protecting economic value in a firm by managing exposure to financial risk – principally operational, credit, and market risk.",
  },
  {
    name: "Fleet Maintenance Management",
    slug: "fleet-maintenance-management",
    definition:
      "The management of all aspects involving vehicle upkeep to ensure fleets are fit for use and in good operating condition.",
  },
  {
    name: "Fleet Management",
    slug: "fleet-management",
    definition:
      "The process of overseeing the systems, processes, and tools that work together to control the entire lifecycle of motor vehicles and equipment to keep them running efficiently and safely.",
  },
  {
    name: "Fleet Management Systems",
    slug: "fleet-management-systems",
    definition:
      "The process of leveraging software to improve fleet efficiency, vehicle tracking, engine diagnostics, driver safety, and other aspects of fleet management.",
  },
  {
    name: "Fleet Optimization",
    slug: "fleet-optimization",
    definition:
      "The process of maximizing the productivity of a fleet to reduce costs and improve the efficiency of operations.",
  },
  {
    name: "Forecasting",
    slug: "forecasting",
    definition:
      "The process of making predictions of the future based on past and present data and most commonly by analysis of trends.",
  },
  {
    name: "Fraud Prevention",
    slug: "fraud-prevention",
    definition:
      "The implementation of a strategy to detect fraudulent transactions or actions and prevent these actions from causing financial and reputational damage to the organization",
  },
  {
    name: "Fueling Operations Management",
    slug: "fueling-operations-management",
    definition:
      "Oversight of the safe and efficient delivery, storage, and/or dispensing of fuel in a manner which fulfils quality standards and regulatory requirements.",
  },
  {
    name: "Gas Distribution Systems Management",
    slug: "gas-distribution-systems-management",
    definition:
      "The process of designing and advising on smooth and safe supply or utilization of gas, including the development, construction, installation, operation, maintenance, and repair of gas and gas-related technology.",
  },
  {
    name: "Gas Operations",
    slug: "gas-operations",
    definition:
      "The development and implementation of proactive asset management strategies to optimize a system, improving the performance and efficiency of gas transmission and distribution systems by operators.",
  },
  {
    name: "Gas Transmission Maintenance",
    slug: "gas-transmission-maintenance",
    definition:
      "The process of maintaining the gas transmission line that transports gas from a common source of supply to a distribution system.",
  },
  {
    name: "Generation Dispatch Estimation",
    slug: "generation-dispatch-estimation",
    definition:
      "The approach to the calculation of power plant dispatch, estimating power output for the purposes of monitoring emissions and fuel cost impacts.",
  },
  {
    name: "Go-To-Market Strategies",
    slug: "go-to-market-strategies",
    definition:
      "The utilization of organizational planning resources to deliver a unique value proposition to customers and achieve competitive advantage.",
  },
  {
    name: "Government Relations",
    slug: "government-relations",
    definition:
      "The process of influencing public policy at all levels of governance, including local, regional, national, and even global.",
  },
  {
    name: "Graphic Design",
    slug: "graphic-design",
    definition:
      "The process of projecting visual communications intended to transmit specific messages to a target audience.",
  },
  {
    name: "Grid Interconnection Studies",
    slug: "grid-interconnection-studies",
    definition:
      "An analysis of the technical and/or economic impacts of achieving a higher share of variable renewable energy in the electricity mix.",
  },
  {
    name: "Grid Operations Management",
    slug: "grid-operations-management",
    definition:
      "The development and implementation of proactive asset management strategies to optimize a grid, improving the performance and efficiency of electricity transmission and distribution systems by grid operators.",
  },
  {
    name: "Health and Safety Management",
    slug: "health-and-safety-management",
    definition:
      "The process of designing, developing, and implementing various programs and initiatives to help manage health and safety of employees and visitors.",
  },
  {
    name: "Health, Safety, and Environmental (HSE) Risk Management",
    slug: "health-safety-and-environmental-hse-risk-management",
    definition:
      "The process of identifying potential safety and environmental hazards and minimize their impact to the operation of the organization.",
  },
  {
    name: "Health, Safety, and Environmental Management",
    slug: "health-safety-and-environmental-management",
    definition:
      "The systematic approach to maintain health and safety in the workplace and protect the environment.",
  },
  {
    name: "Hedging Strategies",
    slug: "hedging-strategies",
    definition:
      "A risk management strategy employed to offset losses in investments by taking an opposite position in a related asset.",
  },
  {
    name: "Horizon Scanning",
    slug: "horizon-scanning",
    definition:
      "A technique for detecting early signs of potentially important developments through a systematic examination of potential threats and opportunities.",
  },
  {
    name: "HR Business Partnering",
    slug: "hr-business-partnering",
    definition:
      "The process of acting as a trusted advisor and building strong relationships with colleagues and the business to drive business performance.",
  },
  {
    name: "HR Service Delivery",
    slug: "hr-service-delivery",
    definition:
      "The approach in which an organization provides HR services to and interacts with employees, which spans the entire employee lifecycle from hire to retire, such as onboarding, payroll, and benefits.",
  },
  {
    name: "Human Resources (HR) Transformation",
    slug: "human-resources-hr-transformation",
    definition:
      "The process to facilitate evolution of the HR function such that service delivery, talent, and technology are seamlessly adapted and integrated into HR strategy to create greater business value — by driving both operational excellence and strategic impact.",
  },
  {
    name: "Identity and Access Management (IAM)",
    slug: "identity-and-access-management-iam",
    definition:
      "Identity and access management (IAM) is a framework of business processes, policies, and technologies that facilitates the management of electronic or digital identities, ensuring that the right users have the appropriate access to technology resources.",
  },
  {
    name: "Incident Management",
    slug: "incident-management",
    definition:
      "Ensuring that any incidents affecting processes and performances of relevant technology services or systems are managed appropriately to mitigate risk and minimize disruption.",
  },
  {
    name: "Incident Response",
    slug: "incident-response",
    definition:
      "An organized approach to addressing and managing the aftermath of a security breach or cyberattack, also known as an IT incident, computer incident, or security incident.",
  },
  {
    name: "Information Security Management",
    slug: "information-security-management",
    definition:
      "The process for defining and managing controls that an organization needs to implement to ensure that it is sensibly protecting the confidentiality, availability, and integrity of its information assets from threats and vulnerabilities.",
  },
  {
    name: "Information Technology Auditing",
    slug: "information-technology-auditing",
    definition:
      "The examination and evaluation of an organization's information systems against recognized standards or established policies.",
  },
  {
    name: "Infrastructure as Code (IaC)",
    slug: "infrastructure-as-code-ia-c",
    definition:
      "The process of managing and provisioning infrastructure through code instead of through manual processes.",
  },
  {
    name: "Innovation Management",
    slug: "innovation-management",
    definition:
      "The process of taking innovative ideas from their inception to implementation.",
  },
  {
    name: "Inspection Management",
    slug: "inspection-management",
    definition:
      "A critical process of quality control that involves measuring, examining, and reviewing materials against standard requirements to ensure materials or components conform to the latest standards and guidelines.",
  },
  {
    name: "Insurance Claims Investigation",
    slug: "insurance-claims-investigation",
    definition:
      "The process of examining the details of a claim to determine its authenticity, relying on evidence, interviews, and records to come to a conclusion.",
  },
  {
    name: "Insurance Management",
    slug: "insurance-management",
    definition:
      "The ability to understand and communicate the contract between the insurer and the policyholder, which determines the claims which the insurer is legally required to pay.",
  },
  {
    name: "Internal Control Design",
    slug: "internal-control-design",
    definition:
      "The process of structuring a system of internal control that provides the control environment, risk assessment, control activities, information and communication, and monitoring for an organization.",
  },
  {
    name: "Internal Controls",
    slug: "internal-controls",
    definition:
      "A process for assuring of an organization's objectives in operational effectiveness and efficiency, reliable financial reporting, and compliance with laws, regulations and policies. A broad concept, internal control involves everything that controls risks to an organization.",
  },
  {
    name: "Intrusion Detection",
    slug: "intrusion-detection",
    definition:
      "The use of security analytics, including the outputs from intelligence analysis, predictive research, and root cause analysis to search for and detect potential breaches or identify recognized indicators and warnings. Also, monitoring and collating external vulnerability reports for organizational relevance, ensuring that relevant vulnerabilities are rectified through formal change processes.",
  },
  {
    name: "Inventory Management",
    slug: "inventory-management",
    definition:
      "The process of managing overall inventory of the organization by controlling, overseeing, maintaining, and properly storing.",
  },
  {
    name: "Inventory Optimization",
    slug: "inventory-optimization",
    definition:
      "The process for balancing the amount of working capital that's tied up in inventory with service-level goals across multiple stock-keeping units (SKUs) while considering all demand and supply volatility situations.",
  },
  {
    name: "Investigation Management",
    slug: "investigation-management",
    definition:
      "The process of controlling the tasks, information, and people involved with an investigation.",
  },
  {
    name: "Investor Relationship Management",
    slug: "investor-relationship-management",
    definition:
      "The process of engaging with shareholders to enable them to communicate their views, concerns, ideas, and opinions to the board of directors and managers.",
  },
  {
    name: "Issue Management",
    slug: "issue-management",
    definition:
      "A process put in place to deal with incidents and problem management within a system, ensuring problems get resolved and issues are addressed at the right level.",
  },
  {
    name: "IT Administration",
    slug: "it-administration",
    definition:
      "The upkeep, configuration, and reliable operation of computer systems.",
  },
  {
    name: "IT Risk Management",
    slug: "it-risk-management",
    definition:
      "The development of policies, procedures, and technologies to mitigate threats from malicious actors and reduce information technology vulnerabilities.",
  },
  {
    name: "IT Security Management",
    slug: "it-security-management",
    definition:
      "The protection of information and information systems against unauthorized access or modification of information.",
  },
  {
    name: "Job Evaluations",
    slug: "job-evaluations",
    definition:
      "The systematic process of determining the relative value of different jobs in an organization.",
  },
  {
    name: "Knowledge Management",
    slug: "knowledge-management",
    definition:
      "The process of identifying, creating, organizing, sharing, using, storing, and disseminating knowledge and information within the organization.",
  },
  {
    name: "Labor and Employment Law",
    slug: "labor-and-employment-law",
    definition:
      "The knowledge of the body of law concerned with the legal relationship between employers and employees.",
  },
  {
    name: "Labor Management Relations",
    slug: "labor-management-relations",
    definition:
      "The process to manage the interaction of employees, their exclusive representatives, and management to resolve bilaterally concerns affecting the working conditions of bargaining unit.",
  },
  {
    name: "Layout Designs",
    slug: "layout-designs",
    definition:
      "The process of utilizing visual elements on-screen or on-paper to plan, communicate, and evaluate construction and space planning objectives.",
  },
  {
    name: "Learning Assessments",
    slug: "learning-assessments",
    definition:
      "The process of analyzing and assessing the effectiveness of the learning and whether performance and business objectives were met.",
  },
  {
    name: "Learning Management Systems",
    slug: "learning-management-systems",
    definition:
      "A software application or web-based technology used to plan, implement, and assess a specific learning process.",
  },
  {
    name: "Learning Strategies",
    slug: "learning-strategies",
    definition:
      "The process of building and transmitting knowledge within the organization to maximize and improve business outcomes.",
  },
  {
    name: "Lease Management",
    slug: "lease-management",
    definition:
      "The process of overseeing the organization’s lease portfolio, including tracking and optimizing real estate and equipment leases to lower costs and achieve compliance.",
  },
  {
    name: "Legal Consulting",
    slug: "legal-consulting",
    definition:
      "The process of providing an opinion regarding the substance or procedure of the law in relation to a particular factual situation.",
  },
  {
    name: "Legal Liability",
    slug: "legal-liability",
    definition:
      "The understanding of concepts that result in a legally enforceable obligation to a third party to pay damages or to make restitution in accordance with an award of a court (i.e. negligence).",
  },
  {
    name: "Legal Research",
    slug: "legal-research",
    definition:
      "The process of identifying and retrieving information necessary to support legal decision-making.",
  },
  {
    name: "Legal Writing",
    slug: "legal-writing",
    definition:
      "The process of analyzing fact patterns and the presenting of arguments in documents such as legal memoranda and briefs.",
  },
  {
    name: "Line Clearances",
    slug: "line-clearances",
    definition:
      "The process of inspecting, managing, and maintaining the clearance of vegetation from power line networks.",
  },
  {
    name: "Liquidity Management",
    slug: "liquidity-management",
    definition:
      "The process of optimizing, maximizing, and safeguarding an organization's liquidity and maintaining a cash position to ensure the business has cash available when needed.",
  },
  {
    name: "Litigation",
    slug: "litigation",
    definition:
      "The process of analyzing and determining the right business legal structure of an organization that aligns with its needs and goals.",
  },
  {
    name: "Load Estimation",
    slug: "load-estimation",
    definition:
      "Analysis undertaken to produce a quantified estimate of a facility's electricity load and consumption.",
  },
  {
    name: "Lobbying",
    slug: "lobbying",
    definition:
      "The process of promoting the interests of an associated organization and influencing the decisions of the government.",
  },
  {
    name: "Logistics Management",
    slug: "logistics-management",
    definition:
      "The part of the supply chain process that plans, implements, and controls the efficient, effective flow, transportation, and storage of goods, services, and related information from the point of origin to the point of consumption to meet operational requirements.",
  },
  {
    name: "Loss Prevention Control",
    slug: "loss-prevention-control",
    definition:
      "An organized effort by a business to reduce any losses of revenue from theft, fraud, damage, administrative errors etc.",
  },
  {
    name: "Maintenance Management",
    slug: "maintenance-management",
    definition:
      "Management of activities such as repair work, servicing, replacing, inspecting, and testing of equipment.",
  },
  {
    name: "Market Analysis",
    slug: "market-analysis",
    definition:
      "The process of analyzing competing products, services, and organizations in the external business environment to identify the strengths, weaknesses, opportunities, and threats of a company.",
  },
  {
    name: "Market Messaging",
    slug: "market-messaging",
    definition:
      "The development of a plan on how to attract target markets to a brand, its services, and products and equity through creative and persuasive marketing messages.",
  },
  {
    name: "Market Research",
    slug: "market-research",
    definition:
      "The process of determining the viability of a new service or product through research conducted directly with potential customers.",
  },
  {
    name: "Marketing Automation",
    slug: "marketing-automation",
    definition:
      "The process of using software platforms and technologies designed for marketing departments and organizations to more effectively market on multiple channels online and automate repetitive tasks.",
  },
  {
    name: "Marketing Campaigns",
    slug: "marketing-campaigns",
    definition:
      "The process of creating and managing overall strategy for integrated marketing campaigns that help support business goals and objectives.",
  },
  {
    name: "Materials Management",
    slug: "materials-management",
    definition:
      "The planning, procurement, storing, and distribution of materials to ensure they are available as required to meet operational requirements.",
  },
  {
    name: "Mechanical Engineering",
    slug: "mechanical-engineering",
    definition:
      "An engineering discipline concerned with the study, design, and application of equipment, devices, and systems which deliver gas.",
  },
  {
    name: "Mergers and Acquisitions",
    slug: "mergers-and-acquisitions",
    definition:
      "The process of managing transactions in which the ownership of companies, other business organizations, or their operating units are transferred or consolidated with other entities as well as identifying possible business investment opportunities.",
  },
  {
    name: "Meter Installation",
    slug: "meter-installation",
    definition: "The process of installing gas meters.",
  },
  {
    name: "Meter Testing",
    slug: "meter-testing",
    definition:
      "The process of  testing gas meters to ensure they operate at the intended performance standards.",
  },
  {
    name: "Metrics Monitoring",
    slug: "metrics-monitoring",
    definition:
      "The process of monitoring performance indicators that demonstrate how effectively a unit, business, function, or company is achieving key objectives.",
  },
  {
    name: "Network Administration",
    slug: "network-administration",
    definition:
      "The use of network monitoring to determine, in real time, whether a network is running optimally, to optimize and improve the average percentage of time during which the network is performing its intended function.",
  },
  {
    name: "Network Architecture",
    slug: "network-architecture",
    definition:
      "The process of evaluating, understanding and scoping the IT network to be implemented for the organization, while also specifying a network's physical components and its functional organization and configuration, its operational principles and procedures, as well as communication protocols used.",
  },
  {
    name: "Network Configurations",
    slug: "network-configurations",
    definition:
      "The process of configuring network hardware and software components according to organizational guidelines and technical requirements. This includes the implementation and configuration of multiple servers, network devices, and network management tools as well as the management of user network access to ensure stable and reliable network operations.",
  },
  {
    name: "Network Management",
    slug: "network-management",
    definition:
      "The process of managing the network for fault and performance using various tools and technologies to keep up with the business requirements.",
  },
  {
    name: "Network Penetration Testing",
    slug: "network-penetration-testing",
    definition:
      "The practice of testing a computer system, network or web application to find security vulnerabilities that an attacker could exploit. Penetration testing can be automated with software applications or performed manually.",
  },
  {
    name: "Network Security Management",
    slug: "network-security-management",
    definition:
      "The process of providing complete visibility into the network and generating data for assets (asset groupings and classifications), firewalls, applications, ports, protocols, VPNs, NAT, and security policies and vendor devices.",
  },
  {
    name: "Nondestructive Evaluation (NDE)",
    slug: "nondestructive-evaluation-nde",
    definition:
      "The process of using non-invasive procedures to inspect the condition of a material or measure a characteristic of an object without creating damage or disassembly.",
  },
  {
    name: "Occupational Health and Safety Management",
    slug: "occupational-health-and-safety-management",
    definition:
      "Management of the system-wide structure that allows the organization to consistently identify and control its health and safety risks, reducing the potential for incidents, helping achieve compliance with health and safety legislation, and continually improving performance.",
  },
  {
    name: "Onboarding",
    slug: "onboarding",
    definition:
      "Design and or implement the organization’s preboarding and onboarding experience for new hires, and define essential components of the preboarding and onboarding experience to guide design of integrated programs and the enhancement of the overall experience for new joiners.",
  },
  {
    name: "Operating Systems",
    slug: "operating-systems",
    definition:
      "System software that manages computer hardware, software resources, and provides common services for computer programs.",
  },
  {
    name: "Operational Excellence",
    slug: "operational-excellence",
    definition:
      "A framework for businesses to focus on growth and execute their strategy better than their competitors.",
  },
  {
    name: "Operational HR",
    slug: "operational-hr",
    definition:
      "A set of human resources activities and processes that help the HR department to run effectively, efficiently, and in support of HR strategies and objectives.",
  },
  {
    name: "Operations Management",
    slug: "operations-management",
    definition:
      "The process of overseeing the proactive, organized, and systematic management of operations across a power generation system.",
  },
  {
    name: "Organization Transformation",
    slug: "organization-transformation",
    definition:
      "Drive programs that impact organization-wide structures, processes, and behaviors in alignment with business strategy, workforce trends, and disruptive technologies to enhance organizational effectiveness and performance.",
  },
  {
    name: "Organizational Redesign",
    slug: "organizational-redesign",
    definition:
      "The ability to reconfigure tasks within and across jobs to optimize human-machine collaboration, usage of alternative talent pools and flexible work models across the business for enhancement of efficiency and capabilities.",
  },
  {
    name: "Organizational Strategy",
    slug: "organizational-strategy",
    definition:
      "The development of a unique plan or framework that is long-term in nature, designed with an objective to gain a competitive advantage over other market participants while delivering both on customer/client and stakeholder promises (i.e. shareholder value).",
  },
  {
    name: "Outage Management",
    slug: "outage-management",
    definition:
      "The process to manage the maintenance and restoration of power in the event of a planned/unplanned outage.",
  },
  {
    name: "Partnership Management",
    slug: "partnership-management",
    definition:
      "Proactive efforts to ensure growth, enabling partners to drive adoption, expansion, and renewals.",
  },
  {
    name: "Partnership Strategy",
    slug: "partnership-strategy",
    definition:
      "The process of identifying and selecting the right partners and driving more revenue for categories through these partnerships.",
  },
  {
    name: "Payroll Processing",
    slug: "payroll-processing",
    definition:
      "The processing of all payroll, time sheets, and related documents, ensuring proper payment, maintaining accurate payroll records, and reconciling deductions with monthly billings.",
  },
  {
    name: "Payroll Systems",
    slug: "payroll-systems",
    definition:
      "The process of operating, maintaining, and implementation payroll system to store and organize all the tasks of employee payment and tax filing.",
  },
  {
    name: "People Analytics",
    slug: "people-analytics",
    definition:
      "The practice of collecting and applying talent data to inform and improve talent management decisions and outcomes.",
  },
  {
    name: "Performance Management",
    slug: "performance-management",
    definition:
      "The process of ensuring that a set of activities and outputs meets an organization's goals in an effective and efficient manner.",
  },
  {
    name: "Photography",
    slug: "photography",
    definition:
      "The process of capturing and editing an image with a specific intent.",
  },
  {
    name: "Physical Security Management",
    slug: "physical-security-management",
    definition:
      "The process of implementing, improving, and maintaining the protection of people, property, and physical assets of an organization.",
  },
  {
    name: "Policy Analysis",
    slug: "policy-analysis",
    definition:
      "The evaluation and study of the formulation, adoption, and implementation of a principle or course of action intended to improve economic, social, or other public issues. Policy analysis is concerned primarily with policy alternatives that are expected to produce novel solutions.",
  },
  {
    name: "Policy Design",
    slug: "policy-design",
    definition:
      "The effort to develop efficient and effective policies and the tools to deliver them.",
  },
  {
    name: "Policy Guidance",
    slug: "policy-guidance",
    definition:
      "The process of recommending to relevant stakeholders on whether to take a position on a policy issue that impacts the organization.",
  },
  {
    name: "Policy Management",
    slug: "policy-management",
    definition:
      "The process of creating, implementing, and maintaining policies and procedures within an organization.",
  },
  {
    name: "Political Strategies",
    slug: "political-strategies",
    definition:
      "The establishment of a plan to win and sustain formal influence over major systems such as a society, community, authorities, or organization.",
  },
  {
    name: "Portfolio Optimization",
    slug: "portfolio-optimization",
    definition:
      "The method of selecting or distributing the best asset portfolio which gives back the most profitable rate of return for each unit of risk taken.",
  },
  {
    name: "Power Distribution Systems Management",
    slug: "power-distribution-systems-management",
    definition:
      "The process of managing the delivery of power to end users via interconnected systems, ensuring a safe, efficient, and compliant operation.",
  },
  {
    name: "Power Engineering",
    slug: "power-engineering",
    definition:
      "A subfield of electrical engineering that deals with the generation, transmission, distribution, and utilization of electric power, and the electrical apparatus connected to such systems.",
  },
  {
    name: "Power Flow Analysis and Modeling",
    slug: "power-flow-analysis-and-modeling",
    definition:
      "A quantitative analysis of the flow of power in an interconnected system.",
  },
  {
    name: "Power Generation Optimization",
    slug: "power-generation-optimization",
    definition:
      "The process of maximizing the efficiency of power generation systems to minimize the total costs of power generation while considering reliability and environmental constraints.",
  },
  {
    name: "Power Monitoring",
    slug: "power-monitoring",
    definition:
      "The process of using the analysis of energy information as a basis to eliminate waste, reduce and control current level of energy use, and improve the existing operating procedures.",
  },
  {
    name: "Power Plant/Substation Design",
    slug: "power-plant-substation-design",
    definition:
      "The application of engineering and design processes and principles to determine the layout, architecture, and/or network of a power plant or substation.",
  },
  {
    name: "Power Scheduling",
    slug: "power-scheduling",
    definition:
      "The logistical planning of energy commodity purchases and sales in the energy market.",
  },
  {
    name: "Power System Dispatch Strategy",
    slug: "power-system-dispatch-strategy",
    definition:
      "The process of selecting the best approach to optimally dispatch power to end users through power systems.",
  },
  {
    name: "Power Systems Performance Monitoring",
    slug: "power-systems-performance-monitoring",
    definition:
      "The ability to track and evaluate data on power systems and their components to optimize the system and its operational performance.",
  },
  {
    name: "Power Systems Planning",
    slug: "power-systems-planning",
    definition:
      "The development of plans for designing, constructing, and operating a power system and its elements.",
  },
  {
    name: "Power Transaction Tagging",
    slug: "power-transaction-tagging",
    definition:
      "The process of electronically recording the details of a transaction to transfer energy from a source point to a sink where the energy is scheduled for transmission across one or more balancing authority area boundaries.",
  },
  {
    name: "Predictive Analytics",
    slug: "predictive-analytics",
    definition:
      "A variety of statistical techniques from data mining, predictive modelling, and machine learning that analyze current and historical facts to make predictions about future or otherwise unknown events.",
  },
  {
    name: "Predictive Maintenance",
    slug: "predictive-maintenance",
    definition:
      "The use of data-driven, proactive maintenance methods that are designed to analyze the condition of equipment and help predict when maintenance should be performed.",
  },
  {
    name: "Pressure Regulation and Control",
    slug: "pressure-regulation-and-control",
    definition:
      "The process of maintaining distribution system pressure within certain limits.",
  },
  {
    name: "Pricing Analysis",
    slug: "pricing-analysis",
    definition:
      "The process of deciding if the asking price for a product or service is fair and reasonable, without examining the specific cost and profit calculations the vendor used in arriving at the price.",
  },
  {
    name: "Pricing Strategies",
    slug: "pricing-strategies",
    definition:
      "A model or method used to establish the best price for a product or service.",
  },
  {
    name: "Prioritization",
    slug: "prioritization",
    definition:
      "The process of determining the order in which tasks or projects should be completed by evaluating the benefits and impact that completion would bring.",
  },
  {
    name: "Process Automations",
    slug: "process-automations",
    definition:
      "The technology-enabled automation of complex business processes. It can streamline a business for simplicity, achieve digital transformation, increase service quality, improve service delivery, or contain costs. It consists of integrating applications, restructuring labor resources, and using software applications throughout the organization. Also known as business automation or digital transformation.",
  },
  {
    name: "Process Controls Engineering",
    slug: "process-controls-engineering",
    definition:
      "The active changing of a production process and related equipment to ensure that they are running reliably and effectively. This includes monitoring equipment and process parameters, planning upgrades and maintenance, and developing and implementing changes that improve uptime and output quality.",
  },
  {
    name: "Process Design",
    slug: "process-design",
    definition:
      "The process of developing effective business processes and workflows that are effective, scalable, and easy to replicate.",
  },
  {
    name: "Process Mapping",
    slug: "process-mapping",
    definition:
      "Activities involved in defining what a business entity does, who is responsible, to what standard a business process should be completed, and how the success of a business process can be determined.",
  },
  {
    name: "Process Standardization",
    slug: "process-standardization",
    definition:
      "The establishment of a set system of attending to tasks and operations to improve operational performance and reduce costs through decreased process errors.",
  },
  {
    name: "Procurement",
    slug: "procurement",
    definition:
      "The process of sourcing and purchasing goods and services from an external source, like a third-party vendor or supplier.",
  },
  {
    name: "Procurement Management",
    slug: "procurement-management",
    definition:
      "The evaluation, selection, and creation of formal contractual agreements as well as managing the company’s ongoing supplier relationships.",
  },
  {
    name: "Procurement Strategies",
    slug: "procurement-strategies",
    definition:
      "A long-term plan to build strategic relationships with competitive suppliers to acquire the necessary goods and services of the best quality in a specific cost range and have them delivered on time.",
  },
  {
    name: "Product Development",
    slug: "product-development",
    definition:
      "The creation, innovation, enhancement or improvement of an existing product, or developing an entirely new kind of product to satisfy the requirements of its end-users.",
  },
  {
    name: "Product Marketing",
    slug: "product-marketing",
    definition:
      "The process of bringing a product to market, including deciding the product's positioning and messaging, launching the product, and ensuring salespeople and customers understand it.",
  },
  {
    name: "Product Strategies",
    slug: "product-strategies",
    definition:
      "The process of outlining a company's strategic vision for its product offerings by stating where the products are going, how they will get there, and why they will succeed.",
  },
  {
    name: "Professional Advocacy",
    slug: "professional-advocacy",
    definition:
      "The efforts of an individual or group to effectively communicate, convey, negotiate, or assert the interests, desires, needs, and rights of yourself, another person, or the position of an organization.",
  },
  {
    name: "Program Evaluations",
    slug: "program-evaluations",
    definition:
      "The process of analyzing information about the activities, characteristics, and outcomes of programs for the purpose of reducing uncertainties, improving effectiveness, and making decisions.",
  },
  {
    name: "Project and Program Management",
    slug: "project-and-program-management",
    definition:
      "The ability to monitor the progress of a project’s timeline, scope, and, if applicable, budget, while also reporting the project status to stakeholders and identifying obstacles to completing the project in accordance with the plan, realigning and refreshing the approach and plan as required.",
  },
  {
    name: "Project Controls",
    slug: "project-controls",
    definition:
      "A process that encompasses the resources, procedures, and tools for the planning, monitoring, and controlling of all phases of a project lifecycle.",
  },
  {
    name: "Project Management Governance",
    slug: "project-management-governance",
    definition:
      "The process of setting rules, procedures, and policies that determine how projects are managed and overseen as well as how project decisions are made.",
  },
  {
    name: "Project Risk Management",
    slug: "project-risk-management",
    definition:
      "The process of identifying, analyzing and responding to any risk that arises over the life cycle of a project to ensure the project remains on track and meets its goal.",
  },
  {
    name: "Property Facilities Management",
    slug: "property-facilities-management",
    definition:
      "The process of planning and designing short- and long-term property and facility plans that align with the organization business plans.",
  },
  {
    name: "Public Affairs Strategies",
    slug: "public-affairs-strategies",
    definition:
      "The process of developing a strategic approach to engaging with the general public using media and communications.",
  },
  {
    name: "Purchase Orders",
    slug: "purchase-orders",
    definition:
      "A set of established principles and practices that ensure availability of complete, up-to-date information on specific or multiple purchase order(s) to ensure that every purchase is necessary, justified, and optimized for costs.",
  },
  {
    name: "Quality Assurance Management",
    slug: "quality-assurance-management",
    definition:
      "The process to ensure organizational activities are designed to ensure that an organization is providing the best possible product or service to customers.",
  },
  {
    name: "Quality Auditing",
    slug: "quality-auditing",
    definition:
      "The process of systematic examination of a system carried out by an internal or external quality auditor or an audit team.",
  },
  {
    name: "Quality Evaluation",
    slug: "quality-evaluation",
    definition:
      "A part of the quality management process focused on conducting testing to ensure that standard requirements will be fulfilled.",
  },
  {
    name: "Quality Management",
    slug: "quality-management",
    definition:
      "A process to ensure that an organization's products or services are consistently delivered to its customers according to set quality standards. It has four main components: quality planning, quality assurance, quality control, and quality improvement.",
  },
  {
    name: "Quality Standards Management",
    slug: "quality-standards-management",
    definition:
      "The ability to develop, review, and communicate clear, quality expectations and standards within an organization that are aligned to the company's values and business objectives.",
  },
  {
    name: "Rate Design and Forecasting",
    slug: "rate-design-and-forecasting",
    definition:
      "The process of applying frameworks to set prices for the reliable provision of electricity and energy services while also predicting the future values of energy rates.",
  },
  {
    name: "Real Estate Disposition",
    slug: "real-estate-disposition",
    definition:
      "The act of selling, subleasing, or conducting a lease buyout of commercial real estate property.",
  },
  {
    name: "Real Estate Transactions",
    slug: "real-estate-transactions",
    definition:
      "The process of helping source, analyze, perform due diligence on, and negotiate the purchase of ownership or rights over a real estate property.",
  },
  {
    name: "Records Management",
    slug: "records-management",
    definition:
      "The creation, distribution, usage, maintenance, and disposition of recorded information cultivated as evidence of business activities and transactions.",
  },
  {
    name: "Recruitment Marketing",
    slug: "recruitment-marketing",
    definition:
      "The marketing process of capturing candidates’ interest in your company via advertisements, for the purpose of developing a talent pipeline.",
  },
  {
    name: "Regulatory Compliance",
    slug: "regulatory-compliance",
    definition:
      "The process of advising on an organization's adherence to laws, regulations, permits, tariffs, guidelines, and specifications relevant to its business processes.",
  },
  {
    name: "Regulatory Strategies",
    slug: "regulatory-strategies",
    definition:
      "The process of identifying the regulatory elements required to execute a business strategy and defining necessary activities related to those elements, while also establishing a strategy for handling regulations and compliance which is crucial for maintaining competitive advantage.",
  },
  {
    name: "Relationship Management",
    slug: "relationship-management",
    definition:
      "A strategy in which an organization maintains an ongoing level of engagement with its audience and supply chain to create and sustain a positive relationship.",
  },
  {
    name: "Reputation Management",
    slug: "reputation-management",
    definition:
      "The practice of influencing stakeholder perceptions and public conversations about an organization and its brands.",
  },
  {
    name: "Requirements Management",
    slug: "requirements-management",
    definition:
      "The process of documenting, analyzing, tracing, prioritizing, and agreeing on requirements and then controlling change while also communicating to relevant stakeholders.",
  },
  {
    name: "Researching",
    slug: "researching",
    definition:
      "The process of investigating and studying materials and sources to establish facts and reach new conclusions.",
  },
  {
    name: "Resource Optimization",
    slug: "resource-optimization",
    definition:
      "The process of allocating and managing resources in the most efficient way possible.",
  },
  {
    name: "Resource Planning",
    slug: "resource-planning",
    definition:
      "The process of designing and planning the capacity of resources to ensure they are used in the most effective way to serve their purpose.",
  },
  {
    name: "Risk Analytics",
    slug: "risk-analytics",
    definition:
      "The process of using a range of techniques and technologies to extrapolate insights, calculate likely scenarios, and predict future events – especially identifying those that may jeopardize the success of a project or achieving a goal.",
  },
  {
    name: "Risk Framework Design",
    slug: "risk-framework-design",
    definition:
      "The process of defining the structured process used to identify potential threats to an organization and the strategy for eliminating or minimizing impact of these risks, as well as mechanisms to effectively monitor and evaluate this strategy.",
  },
  {
    name: "Risk Management",
    slug: "risk-management",
    definition:
      "The process of identifying, evaluating, and managing risks by developing and implementing risk management strategies, frameworks, policies, procedures, and practices.",
  },
  {
    name: "Root Cause Analysis",
    slug: "root-cause-analysis",
    definition:
      "An iterative process designed to investigate and categorize the root causes of events or failures that may have negative impacts to the overall performance of a system and establish a flexible and effective framework for the necessary corrective and preventive actions.",
  },
  {
    name: "Sales Forecasting",
    slug: "sales-forecasting",
    definition:
      "The process of estimating the quantity of goods and services the organization realistically expects to sell over the forecast period, the cost of the goods and services, and the estimated profit.",
  },
  {
    name: "Sales Pipeline Management",
    slug: "sales-pipeline-management",
    definition:
      "The process of tracking and managing of every sales opportunity through every stage to a successful close of a revenue producing contract.",
  },
  {
    name: "Sales Strategy",
    slug: "sales-strategy",
    definition:
      "A documented plan for positioning and selling a product or service to qualified buyers in a way that differentiates company's solution from competitors. Sales strategies are meant to provide clear objectives and guidance to the sales organization.",
  },
  {
    name: "Scenario Planning",
    slug: "scenario-planning",
    definition:
      "The process with which organizations can form an idea of possible future scenarios and how these may affect their strategic objectives.",
  },
  {
    name: "Schedule Management",
    slug: "schedule-management",
    definition:
      "The process of establishing policies and documentation for maintaining, developing, managing, and controlling the schedules for time and resources for the completion of the project.",
  },
  {
    name: "Scheduling Management",
    slug: "scheduling-management",
    definition:
      "The process of planning and maintaining a schedule including appointments, travel, and other logistical planning activities.",
  },
  {
    name: "Security Architecture Design",
    slug: "security-architecture-design",
    definition:
      "The process of interpreting relevant security policies and threat/risk profiles into secure architectural solutions that mitigate the risks, conform to legislation and regulations, and relate to business needs, while also presenting security architecture solutions as a view within broader IT architectures.",
  },
  {
    name: "Security Governance",
    slug: "security-governance",
    definition:
      "The process of developing and disseminating corporate security policies, frameworks, and guidelines to ensure that day-to-day business operations are guarded and well protected against risks, threats, and vulnerabilities.",
  },
  {
    name: "Security Management",
    slug: "security-management",
    definition:
      "The development, documentation, and implementation of policies and procedures to protect an organization's assets.",
  },
  {
    name: "Segmentation Strategy",
    slug: "segmentation-strategy",
    definition:
      "The exercise of dividing accounts into groups based on an analysis of each account's existing revenue contribution and future upside potential.",
  },
  {
    name: "Service Level Agreement (SLA)",
    slug: "service-level-agreement-sla",
    definition:
      "The creation of a contract that establishes a set of deliverables that one party has agreed to provide another.",
  },
  {
    name: "Short Circuit Simulation and Analysis",
    slug: "short-circuit-simulation-and-analysis",
    definition:
      "An examination or evaluation of an electrical system to determine the magnitude of currents that can flow during an electrical fault and compare those values to the ratings of installed equipment and short circuit protection devices.",
  },
  {
    name: "Simulation Models",
    slug: "simulation-models",
    definition:
      "The process of creating models that mimic the operation of an existing or proposed system, providing evidence for decision-making by being able to test different scenarios or process changes.",
  },
  {
    name: "Smart Meters",
    slug: "smart-meters",
    definition:
      "The ability to utilize an electronic system capable of measuring electricity fed into the grid, or electricity consumed from the grid, providing more information than conventional meters.",
  },
  {
    name: "Software Development Life Cycle (SDLC)",
    slug: "software-development-life-cycle-sdlc",
    definition:
      "A process used by the software industry to design, develop, and test high quality software, providing a structured flow of phases to help quickly produce quality software that is ready for production use.",
  },
  {
    name: "Software Testing",
    slug: "software-testing",
    definition:
      "An investigation conducted to provide stakeholders with information about the quality of the software product or service under test.",
  },
  {
    name: "Solution Selling",
    slug: "solution-selling",
    definition:
      "A selling methodology that is a problem-led (rather than product-led) process to determine if and how a change in a product could bring specific improvements desired by the customer.",
  },
  {
    name: "Solutions Design",
    slug: "solutions-design",
    definition:
      "The process to translate high-level technical requirements into alternative design solutions.",
  },
  {
    name: "Sourcing Strategies",
    slug: "sourcing-strategies",
    definition:
      "The process of building a reliable and competitive supplier base, defining pricing strategies and procurement requirements, and ensuring timely delivery of goods and services to meet business objectives.",
  },
  {
    name: "Space Optimization",
    slug: "space-optimization",
    definition:
      "The process of guiding a company's workplace real estate portfolio in support of its core business strategy, which includes providing, maintaining, and managing the work environment across multiple offices and locations and/or supporting the changing space and occupancy needs of a business with an eye toward future demands and concerns.",
  },
  {
    name: "Specification Development",
    slug: "specification-development",
    definition:
      "Developing measurable criteria by which products, components, and projects can be evaluated based on technical requirements.",
  },
  {
    name: "Stakeholder Management",
    slug: "stakeholder-management",
    definition:
      "The process of organizing, communicating, monitoring, and improving relationships with stakeholders.",
  },
  {
    name: "Standards Compliance",
    slug: "standards-compliance",
    definition:
      "The implementation of standards correctly and efficiently, while also ensuring adherence to them.",
  },
  {
    name: "Statistical Data Modeling",
    slug: "statistical-data-modeling",
    definition:
      "The data science approach to apply statistical analysis to datasets with the aim to approach data analysis in a strategic manner and provide intuitive visualizations that aid in identifying relationships between variables and making predictions.",
  },
  {
    name: "Storytelling",
    slug: "storytelling",
    definition:
      "The process of communicating information, tailored to a specific audience, with a compelling narrative.",
  },
  {
    name: "Strategic Audit",
    slug: "strategic-audit",
    definition:
      "The process of setting the direction, timing, and scope of an audit to create a more targeted audit plan, thereby wasting less time in total during the planning phase.",
  },
  {
    name: "Strategic Communication",
    slug: "strategic-communication",
    definition:
      "An all-encompassing roadmap for business, financial, technical and marketing communications. It guides communications across all formats, mediums and audiences, and aligns them with core values, purpose, and desired outcomes.",
  },
  {
    name: "Strategic Insights",
    slug: "strategic-insights",
    definition:
      "A structured and systematic way of using ideas about the future to anticipate and better prepare for change and sustainability of the business.",
  },
  {
    name: "Strategic Planning",
    slug: "strategic-planning",
    definition:
      "An organization's process of defining its strategy, or direction, and making decisions on allocating its resources to pursue this strategy.",
  },
  {
    name: "Supplier Development",
    slug: "supplier-development",
    definition:
      "The process of working with certain suppliers on a one-to-one basis to improve their performance for the benefit of the buying organization.",
  },
  {
    name: "Supplier Evaluations",
    slug: "supplier-evaluations",
    definition:
      "The process of assessing suppliers' performance against qualitative and quantitative supply chain requirements.",
  },
  {
    name: "Supplier Management",
    slug: "supplier-management",
    definition:
      "The systematic, enterprise-wide assessment of suppliers’ strengths, performance, and capabilities with respect to overall business strategy, determination of what activities to engage in with different suppliers, and planning and execution of all interactions with suppliers, in a coordinated fashion across the relationship life cycle to maximize the value realized through those interactions.",
  },
  {
    name: "Supplier Selection",
    slug: "supplier-selection",
    definition:
      "The process of identifying, evaluating, and then contracting suppliers to acquire the necessary materials to support the outputs of the organization.",
  },
  {
    name: "Supply Chain Analytics",
    slug: "supply-chain-analytics",
    definition:
      "The process of evaluating every stage of a supply chain starting from the time the business acquires raw materials or supplies from its suppliers to the delivery of final products to the customers.",
  },
  {
    name: "Supply Chain Audit",
    slug: "supply-chain-audit",
    definition:
      "The process of scheduling and conducting periodic internal reviews and audits to ensure that compliance procedures are followed, while also producing standard audit reports based on findings.",
  },
  {
    name: "Supply Chain Management",
    slug: "supply-chain-management",
    definition:
      "The management of the flow of goods and services; this includes all processes that transform raw materials into final products.",
  },
  {
    name: "Sustainability Management",
    slug: "sustainability-management",
    definition:
      "The process of planning, developing, and rolling out of an organization-wide sustainability strategy. This includes the assessment of the organization's utilization and/or consumption of energy and other resources, the availability and stability of supply sources, and external best practices and standards in sustainability.",
  },
  {
    name: "Sustainability Risk Management",
    slug: "sustainability-risk-management",
    definition:
      "The process of developing a business strategy that aligns profit goals with a company's environmental policies.",
  },
  {
    name: "Sustainable Supply Chains",
    slug: "sustainable-supply-chains",
    definition:
      "The integration of social, ethical, and environmental performance factors into the process of procurement or selecting suppliers, including supplier diversity and other ESG initiatives.",
  },
  {
    name: "Switching",
    slug: "switching",
    definition: "The process of operating a switched-mode power supply.",
  },
  {
    name: "System Load Analysis",
    slug: "system-load-analysis",
    definition:
      "An analysis used to calculate the steady state performance of the system under various possible operating conditions and study the effects of changes in equipment configuration.",
  },
  {
    name: "System Migrations",
    slug: "system-migrations",
    definition:
      "The transfer of business process IT resources to a newer hardware infrastructure or a different software platform for the purpose of keeping up with current technologies and/or to gain better business value.",
  },
  {
    name: "System Testing",
    slug: "system-testing",
    definition:
      "The process of testing conducted on a complete integrated system to evaluate the system's compliance with its specified requirements.",
  },
  {
    name: "Systems Designs",
    slug: "systems-designs",
    definition:
      "The process of designing technical solutions and systems to meet specified business and user requirements that are compatible with established system architectures, as well as organizational and performance standards.",
  },
  {
    name: "Systems Integration",
    slug: "systems-integration",
    definition:
      "The process of linking together various IT systems, services and/or software to enable all of them to work functionally together.",
  },
  {
    name: "Systems Performance Monitoring",
    slug: "systems-performance-monitoring",
    definition:
      "The process of measuring the performance of a system in real time to determine problems and identify other factors that may cause problems in the future.",
  },
  {
    name: "Talent Branding",
    slug: "talent-branding",
    definition:
      "The process of strategizing a new media marketing approach as well as utilizing new platforms to champion employer brand to resonate with diverse groups of talent.",
  },
  {
    name: "Talent Management",
    slug: "talent-management",
    definition:
      "The system or strategy used by an organization to effectively recruit, hire, develop, and retain employees.",
  },
  {
    name: "Talent Sourcing",
    slug: "talent-sourcing",
    definition:
      "The process of establishing talent acquisition strategies and the sourcing plan, e.g., internal vs. external recruitment, for all level jobs.",
  },
  {
    name: "Tax Audits",
    slug: "tax-audits",
    definition:
      "The review and examination of an organization's accounts and financial information to ensure information is reported correctly according to the tax laws and to verify the reported amount of tax is correct.",
  },
  {
    name: "Tax Law",
    slug: "tax-law",
    definition:
      "The rules, policies and laws that oversee the tax process, which involves charges on estates, transactions, property, income, licenses, and more by the government.",
  },
  {
    name: "Tax Planning",
    slug: "tax-planning",
    definition:
      "The analysis of a financial situation or plan to ensure lowest taxes payable or tax efficiency.",
  },
  {
    name: "Tax Research",
    slug: "tax-research",
    definition:
      "The process of identifying relevant binding and persuasive authorities for a specific point of tax law or issue.",
  },
  {
    name: "Technical Assessment",
    slug: "technical-assessment",
    definition:
      "The ability to understand, evaluate, problem-solve, and/or provide guidance on technical systems, processes, and technologies.",
  },
  {
    name: "Technical Documentation",
    slug: "technical-documentation",
    definition:
      "The process of documenting the foundational information about the underlying architecture, materials, and process for interfacing with existing technology. Technical documentation refers to any document that explains the use, functionality, creation, or architecture of a product.",
  },
  {
    name: "Technical Reports",
    slug: "technical-reports",
    definition:
      "A form of objective and formal reporting that presents the objectives, processes, and key outcomes of technical work.",
  },
  {
    name: "Technology Deployments",
    slug: "technology-deployments",
    definition:
      "The process to oversee launching of new technologies and innovative solutions within an organization.",
  },
  {
    name: "Technology Governance",
    slug: "technology-governance",
    definition:
      "The management of technology risks effectively and ensuring that the activities associated with technology are aligned with the organization's overall business objectives.",
  },
  {
    name: "Technology Research",
    slug: "technology-research",
    definition:
      "The process of understanding the design, improvement, and construction of new types of products, equipment, and machinery and how they impact an organization.",
  },
  {
    name: "Threat Modeling",
    slug: "threat-modeling",
    definition:
      "A structured approach of identifying and prioritizing potential threats to a system, and determining the value that potential mitigations would have in reducing or neutralizing those threats.",
  },
  {
    name: "Total Rewards Strategies",
    slug: "total-rewards-strategies",
    definition:
      "A coordinated effort driven by an organization’s overall business strategy to develop a workforce motivated towards excellence and growing with the organization through an effective and inclusive rewards package.",
  },
  {
    name: "Trading Position Management",
    slug: "trading-position-management",
    definition:
      "The management of exposure to risk when trading by aligning positions within intended trading strategies and risk parameters.",
  },
  {
    name: "Training",
    slug: "training",
    definition:
      "The process of teaching a particular skill or type of behavior.",
  },
  {
    name: "Training Delivery Management",
    slug: "training-delivery-management",
    definition:
      "The process of evaluating and deciding on a training delivery method with consideration of budget, size, and type of the workforce, location, time frame, and goals.",
  },
  {
    name: "Transaction Structuring",
    slug: "transaction-structuring",
    definition:
      "The use of various financial instruments to acquire a position in exposure to energy commodities speculation or investment.",
  },
  {
    name: "Transient Analysis",
    slug: "transient-analysis",
    definition:
      "The analysis of how a circuit reacts to a change in its inputs, and how the output eventually transitions between two states.",
  },
  {
    name: "Treasury Management",
    slug: "treasury-management",
    definition:
      "The process of balancing and optimizing the organization's cash needs and cash balances and determining the optimal financing of working capital.",
  },
  {
    name: "Trend Analysis",
    slug: "trend-analysis",
    definition:
      "The process of comparing business data over time to identify any consistent results or trends.",
  },
  {
    name: "Troubleshooting",
    slug: "troubleshooting",
    definition:
      "The act or process of discovering and resolving problems, disputes, or mechanical issues through a systematic, usually iterative, approach.",
  },
  {
    name: "User Acceptance Testing",
    slug: "user-acceptance-testing",
    definition:
      "A type of testing performed by the end user or the client to verify/accept the software system before moving the software application to the production environment.",
  },
  {
    name: "Utility Mapping",
    slug: "utility-mapping",
    definition:
      "The process of identifying and documenting where underground cables or pipes are located. These underground utilities often include electric wiring, telecommunications lines, natural gas, water lines, and wastewater pipes.",
  },
  {
    name: "Value Proposition Development",
    slug: "value-proposition-development",
    definition:
      "A value proposition is a statement that conveys what a brand does and how it differs from competitors. It's typically developed as part of a broader marketing strategy and no more than a few sentences long.",
  },
  {
    name: "Vegetation Management",
    slug: "vegetation-management",
    definition:
      "The removal of trees, shrubs or other vegetation that pose a risk to the reliability of the utility infrastructure and the retention of vegetation that are compatible with the utility infrastructure.",
  },
  {
    name: "Vehicle Purchasing and Leasing",
    slug: "vehicle-purchasing-and-leasing",
    definition: "The process of buying or leasing a vehicle.",
  },
  {
    name: "Vendor Management",
    slug: "vendor-management",
    definition:
      "The process to maintain relationships with key vendors that deliver products, services, and support to the business. Simultaneously, negotiating and managing contracts with key service providers to ensure service levels are set appropriately and met on an ongoing basis.",
  },
  {
    name: "Video Production",
    slug: "video-production",
    definition:
      "The process of overseeing and executing the creation of video content, including storyboarding, filming, and editing.",
  },
  {
    name: "Vulnerability Management",
    slug: "vulnerability-management",
    definition:
      "The process of defining, identifying, classifying and prioritizing vulnerabilities in computer systems, applications, and network infrastructures and providing the organization with the necessary knowledge, awareness, and risk background to understand the threats to its business.",
  },
  {
    name: "Warehouse Management",
    slug: "warehouse-management",
    definition:
      "The process of overseeing operations in a warehouse, including receiving, tracking, and storing inventory.",
  },
  {
    name: "Workflow Optimization",
    slug: "workflow-optimization",
    definition:
      "The process of improving an existing workflow, by reducing operating costs or improving the efficiency of work done.",
  },
  {
    name: "Writing Technical Documents",
    slug: "writing-technical-documents",
    definition:
      "The practice of documenting complex technical processes in an accessible and unambiguous way.",
  },
  {
    name: "Positioning Strategies",
    slug: "positioning-strategies",
    definition:
      "The management of exposure to risk when trading by aligning positions within intended trading strategies and risk parameters.",
  },
  {
    name: "Power Market Analysis",
    slug: "power-market-analysis",
    definition:
      "The process of examining and evaluating regulated or deregtulated energy markets that deal specifically with the trand and supply of energy.",
  },
]

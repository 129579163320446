import { flatten } from "lodash"

import JOB_FAMILY_GROUPS from "@content/job-family-groups"

const allJobFamilies = flatten(
  JOB_FAMILY_GROUPS.filter(g => !!g).map(jfg => jfg.jobFamilies)
)

const getJobTitle = job => {
  return [job.jobTitle, job.section].filter(s => s).join(", ")
}

const getAllJobFamilies = () => allJobFamilies

export { getJobTitle, getAllJobFamilies }
